import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function ErrorPage() {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/");
  });
  return (
    <div className="w-screen h-screen flex justify-center items-center">
      <div className="bg-indigo-900 w-9/12 lg:w-4/12 p-8 rounded-lg text-white">
        <h1 className="text-center font-bold text-2xl">
          Error or Access denied
        </h1>
        <div className="mt-4 text-center">
          You will want to{" "}
          <span
            className="bg-white text-black px-2 rounded-lg mr-1 cursor-pointer"
            onClick={() => navigate("/")}
          >
            login
          </span>
          to access this resource
        </div>
      </div>
    </div>
  );
}
