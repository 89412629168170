import { forwardRef, useState } from "react";
import DatePicker, { CalendarContainer } from "react-datepicker";
import { AppColors } from "../../utils/constants";
import { PrimaryBtn } from "../../Components/Button";
import { toast } from "react-toastify";
import Dropdown from "../../Components/DropDown";

export default function CouseDate({
	setcurrentStep,
	setstepData,
	stepData,
}: any) {
	const [startDate, setStartDate] = useState(new Date());
	const [duration, setduration] = useState("");
	const handledateChange = (utcDateString: any) => {
		const utcDate = new Date(utcDateString);
		setStartDate(utcDate);
		// const localDateString = utcDate.toLocaleString("en-US", {
		// 	timeZone: "Europe/Berlin",
		// }); // Adjust timeZone as per your location
		// alert(utcDate); // Display the local date and time
	};
	const MyContainer = ({ className, children }: any) => {
		return (
			<div
				style={{
					padding: "2px",
					background: AppColors.brandRed,
					color: "#fff",
				}}
			>
				<CalendarContainer className={className}>
					<div
						style={{ background: "#1a0946", color: "#fff", padding: "0.5rem" }}
					>
						What is your favorite day?
					</div>
					<div style={{ position: "relative" }}>{children}</div>
				</CalendarContainer>
			</div>
		);
	};

	const ExampleCustomInput = forwardRef(({ value, onClick }: any, ref: any) => (
		<div
			className="khdatepicker-wrapper "
			onClick={onClick}
			ref={ref}
			style={{ width: "100%" }}
		>
			<h4>Pick class date and time:</h4>
			<div className="text-orange-500">{value}</div>
		</div>
	));

	const getCurrTime = () => {
		return new Date();
	};

	const handleProceed = () => {
		const currentTime = new Date();
		if (currentTime > startDate) {
			toast.error("Selected date and time must be greater than current time");
		} else {
			setstepData([...stepData, startDate, duration]);
			setcurrentStep(3);
		}
	};
	return (
		<div>
			<div className=" py-2 text-gray-600 w-full">
				<div className="border-l-4 border-orange-500 pl-2">
					<h2 className="text-2xl">Date & Time Details</h2>
					<p className="font-light">Set Date and time for this course</p>
				</div>
				<div className="min-h-[80vh] lg:w-5/12 m-auto">
					<DatePicker
						selected={startDate}
						onChange={(date: any) => {
							handledateChange(date);
						}}
						showTimeSelect
						timeFormat="HH:mm"
						timeIntervals={15}
						timeCaption="time"
						// dateFormat="MMMM d, yyyy h:mm aa"
						dateFormat="MMMM d yyy, h:mm:aa"
						customInput={<ExampleCustomInput />}
						calendarContainer={MyContainer}
					/>

					<div className="mt-14">
						<Dropdown
							label="Duration"
							className="w-full"
							placeholder="Select Duartion"
							options={[
								{ value: "60", label: "1 hour" },
								{ value: "90", label: "1hour 30mins" },
								{ value: "120", label: "2 hours" },
								{ value: "150", label: "2 hours 30 mins" },
								{ value: "180", label: "3 hours" },
								{ value: "210", label: "3 hours 30 mins" },
								{ value: "240", label: "4 hours" },
							]}
							onSelect={(value: any) => setduration(value)}
						/>
					</div>

					<div className="w-full mt-10">
						<PrimaryBtn
							isDisabled={
								startDate && duration && getCurrTime() < startDate
									? false
									: true
							}
							title="Proceed"
							btnType="submit"
							onBtnClick={() => handleProceed()}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}
