import styled from "styled-components";
import { AppColors } from "../../utils/constants";

export const Container = styled.div`
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Wrapper = styled.div``;
