import { useEffect, useState } from "react";
import moment from "moment";
import { useNavigate, useRoutes } from "react-router-dom";
import pencil from "../../Assets/icons/pencil.svg";
import AcceptInvite from "../AcceptApplicationInvite";
import studentRequest from "../../classes/request.class";
import {
	Card,
	CardButton,
	CardButtonContainer,
	CardContent,
	CardDate,
	CardDescription,
	CardHeader,
	CardLang,
	CardStatus,
	CardWrapper,
	Download,
	Interactions,
	Schedule,
	SubjectCont,
	ShowMore,
	Break,
	Ribbon,
	RibbonStatus,
} from "./Styles";
import { getStoredClientUser } from "../../utils/LS";
import { formatDateAgo } from "../../utils/some";

type RequestPropT = {
	data: {
		date: any;
		isOpen: boolean;
		isClass: boolean;
		settlement: boolean;
		subject: string;
		language: any;
		description: any;
		duration: number;
		applicants: any;
		createdAt: string;
		schedule: string;
		file?: string;
		merithubTutorID: string;
		_id: string;
	};
};

export default function RequestCard({ data }: RequestPropT) {
	const { userType, userID } = getStoredClientUser();
	const [showMOre, setShowMOre] = useState(false);
	const [popUp, setPopUp] = useState(false);
	const navigate = useNavigate();
	const getTutorApplication = (requestID: string) => {
		if (userID === undefined) {
			navigate("/login");
		}

		if (userType === "Student") {
			studentRequest.get_all_tutor_request(requestID).then((response) => {
				navigate(`/request/${requestID}/tutor-applications`, {
					state: { response, requestID },
				});
			});
		} else {
			navigate(`/apply/${requestID}`, { state: requestID });
		}
	};

	const clickHandler = () => {
		setPopUp(!popUp);
	};

	// useEffect(() => {
	//   console.log("user====>", getStoredClientUser());
	// }, []);

	const scheduleTime = data?.schedule;
	return (
		<>
			<div>
				<Card>
					<Ribbon status={data.isOpen}>
						<RibbonStatus status={data.isOpen}>
							{data.isOpen ? "Open" : "Closed"}
						</RibbonStatus>
					</Ribbon>
					<CardHeader>
						<CardStatus isActive={data.isOpen ? true : false}>
							{/* {data.isOpen ? "Open" : "Closed"} */}
						</CardStatus>
						<CardDate>
							<em>Posted {formatDateAgo(data.createdAt)}</em>
						</CardDate>
					</CardHeader>
					<hr
						style={{ border: "0.55px solid #E5E7E8", marginBottom: "1rem" }}
					/>
					<CardContent>
						<SubjectCont>
							Subject:<span>{data.subject}</span>
						</SubjectCont>

						<Schedule>
							Date:
							{/* @ts-ignore */}
							<span>
								{/* {moment().format(scheduleTime)}{" "} */}
								{moment(scheduleTime).format("l")}
							</span>
						</Schedule>
						<Schedule>
							Time:
							{/* @ts-ignore */}
							<span>
								{/* {moment().format(scheduleTime)}{" "} */}
								{moment(scheduleTime).format("LT")}
							</span>
						</Schedule>
						<Schedule>
							Duration:
							{/* @ts-ignore */}
							<span>
								{/* {moment().format(scheduleTime)}{" "} */}
								{data.duration || 1} hrs
							</span>
						</Schedule>
						<CardDescription>
							{data?.description?.length > 80 && !showMOre
								? data.description.slice(0, 200) + "..."
								: data.description}
						</CardDescription>
						{data?.description?.length > 80 && (
							<ShowMore onClick={() => setShowMOre(!showMOre)}>
								{showMOre ? "Show less" : "Show More"}
							</ShowMore>
						)}
						<Break>
							<CardLang>
								Language: <span>{data.language || "English"}</span>
							</CardLang>
							<CardWrapper>
								<Download>
									{data.isOpen && userType !== "Student" && data.file && (
										<a
											href={data.file}
											style={{ color: "#e74c3c" }}
											download
											title="Download file"
										>
											<i className="fa fa-download" aria-hidden="true"></i>
										</a>
									)}
								</Download>
								<CardButtonContainer>
									{/* {userType === "Student" && ( */}
									<Interactions>
										<img src={pencil} alt="" />
										<span>{data?.applicants?.length}</span>
									</Interactions>
									{data.isOpen && userType !== "Student" && (
										<div
											onClick={() => navigate(`/request/${data?._id}`)}
											className="bg-slate-500 py-2 px-3 rounded-md cursor-pointer text-white"
										>
											Open
										</div>
									)}

									<CardButton
										onClick={() =>
											userType !== "Student"
												? clickHandler()
												: getTutorApplication(data?._id)
										}
										disabled={
											userType !== "Student" || !userID
												? data?.applicants?.find(
														(el: any) => el.userId === userID
												  ) || !data.isOpen
												: !data?.applicants?.length || !data.isOpen
										}
									>
										{userType === "Student"
											? "View applications"
											: data?.applicants?.find(
													(el: any) => el.userId === userID
											  )
											? "Applied"
											: "Apply"}
									</CardButton>
									{data?.applicants?.find(
										(el: any) => el.userId === userID
									) && (
										<CardButton
											disabled={data?.settlement || !data?.isOpen}
											onClick={() =>
												navigate(`/apply/${data?._id}`, {
													state: data?.applicants?.find(
														(el: any) => el.userId === userID
													),
												})
											}
										>
											Edit
										</CardButton>
									)}
								</CardButtonContainer>
							</CardWrapper>
						</Break>
					</CardContent>
				</Card>
			</div>
			{popUp && <AcceptInvite data={data} />}
		</>
	);
}
