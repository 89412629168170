import React from "react";
import { Helmet } from "react-helmet";
import { truncateText } from "./some";

interface MetaDecoratorProps {
  title: string;
  description: string;
  imageUrl: string;
  imageAlt: string;
}

const MetaDecorator: React.FC<MetaDecoratorProps> = ({ title, description, imageUrl }) => {
  return (
    <Helmet>
      <title>{truncateText(title, 40)}</title>
      <meta name="description" content={truncateText(description, 80)} />
      <meta property="og:description" content={truncateText(description, 80)} />
      <meta property="og:url" content={window.location.href} />
      <meta property="og:title" content={title} />
      <meta property="og:image" content={imageUrl} />
      <meta property="og:type" content="website" />
      <meta property="og:image:secure_url" content={imageUrl} />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta property="og:image:type" content="image/jpeg" />
    </Helmet>
  );
};

export default MetaDecorator;
