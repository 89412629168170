import moment from "moment";
import {
	getStoredAuthToken,
	getStoredClientUser,
	removeStoredAuthToken,
} from "./LS";

export function numFormatter(x: number) {
	if (isNaN(x)) return x;

	if (x < 9999) {
		return x;
	}

	if (x < 1000000) {
		return Math.round(x / 1000) + "K";
	}
	if (x < 10000000) {
		return (x / 1000000).toFixed(2) + "M";
	}

	if (x < 1000000000) {
		return Math.round(x / 1000000) + "M";
	}

	if (x < 1000000000000) {
		return Math.round(x / 1000000000) + "B";
	}

	return "1T+";
}

export const ProtectRoute = () => {
	if (getStoredAuthToken() && getStoredClientUser()) {
		window.location.reload();
	} else {
		window.location.reload(); //This route is dynamic, based on isLoggedin state
	}
};

export const Logout = () => {
	removeStoredAuthToken();
	window.location.reload();
	window.location.href = "/";
};

export const truncateText = (str: string, size: number) => {
	return str?.length > size ? str?.substring(0, size - 3) + "..." : str;
};

export function isStartTimeGreaterThanEndTime(
	startTime: string,
	endTime: string
): boolean {
	const [startHour, startMinute] = startTime.split(":").map(Number);
	const [endHour, endMinute] = endTime.split(":").map(Number);

	const startDate = new Date(0, 0, 0, startHour, startMinute);
	const endDate = new Date(0, 0, 0, endHour, endMinute);

	return startDate.getTime() > endDate.getTime();
}

export function dateParser(dateValue: any) {
	const date = dateValue ? new Date(dateValue) : new Date();
	return date;
}

export function getDuration(start: string, end: string) {
	var startTime = moment(start, "hh:mm A");
	var endTime = moment(end, "hh:mm A");
	var durationInMinutes = endTime.diff(startTime, "minutes");
	return durationInMinutes;
}

export const getFriend = (arr: any[], userId: string) => {
	return arr?.find((usr: any) => usr._id !== userId);
};
export const formatDateAgo = (dateTime: string): string => {
	const now = moment();
	const then = moment(dateTime);

	const secondsDiff = now.diff(then, "seconds");
	const minutesDiff = now.diff(then, "minutes");
	const hoursDiff = now.diff(then, "hours");
	const daysDiff = now.diff(then, "days");

	if (secondsDiff < 60) {
		return "just now";
	} else if (minutesDiff < 60) {
		return `${minutesDiff} minute${minutesDiff !== 1 ? "s" : ""} ago`;
	} else if (hoursDiff < 24) {
		return `${hoursDiff} hour${hoursDiff !== 1 ? "s" : ""} ago`;
	} else if (daysDiff === 1) {
		return "yesterday";
	} else if (daysDiff < 7) {
		return `${daysDiff} day${daysDiff !== 1 ? "s" : ""} ago`;
	} else if (daysDiff < 30) {
		const weeksDiff = Math.floor(daysDiff / 7);
		return `${weeksDiff} week${weeksDiff !== 1 ? "s" : ""} ago`;
	} else if (daysDiff < 365) {
		const monthsDiff = Math.floor(daysDiff / 30);
		return `${monthsDiff} month${monthsDiff !== 1 ? "s" : ""} ago`;
	} else {
		const yearsDiff = Math.floor(daysDiff / 365);
		return `${yearsDiff} year${yearsDiff !== 1 ? "s" : ""} ago`;
	}
};
