import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  gap: 20px;
  width: 70%;
  margin: 4rem auto;
  img {
    height: 40px;
    margin-top: 1.4rem;
    cursor: pointer;
  }
  @media (max-width: 450px) {
    flex-direction: column;
    width: 92%;
    gap: 10px;
  }
`;
export const CardWrapper = styled.div`
  width: 100%;
  height: fit-content;
  /* background-color: rgba(255, 252, 251, 0.8); */
  padding: 20px;
  @media (max-width: 700px) {
    width: 85%;
  }
  @media (max-width: 500px) {
    width: 100%;
    padding: 0;
  }
  h1 {
    padding: 0px 20px;
    width: 100%;
    height: 48px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    top: 38px;
    background: #ffffff;
    box-shadow: 0px 2px 50px rgba(0, 0, 0, 0.05);
    display: flex;
    align-items: center;
  }
`;
export const TableWrapper = styled.div`
  min-height: 500px;
  border-radius: 10px;
  background: #fff;
`;
export const TableContainer = styled.table`
  border-collapse: collapse;
  width: 100%;
  margin-top: 20px;
  th {
    background-color: #f5fbf7;
    padding: 10px;
    text-align: left;
    &:nth-child(4) {
      width: 40%;
    }
  }

  td {
    padding: 10px;
  }
  tbody {
    tr {
      border-bottom: 0.5px solid #e2e2e2;
    }
  }
  @media (max-width: 600px) {
    min-height: auto;
  }
`;
