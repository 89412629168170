// import UserLayout from "../../Layouts/UserLayout/UserLayout";
// import {
// 	ChatContainer,
// 	Container,
// 	FirstSection,
// 	Flex,
// 	MessageBody,
// 	MessageHeader,
// 	SecondSection,
// 	Wrapper,
// } from "./Styles";
// import Pen from "../../Assets/icons/pen-square.svg";
// import { FaCircleArrowRight } from "react-icons/fa6";
// import { useEffect, useRef, useState } from "react";
// import userOBJ from "../../classes/user.class";
// import { getStoredClientUser } from "../../utils/LS";
// import { getFriend } from "../../utils/some";
// import { toast } from "react-toastify";
// import Spinner from "../../Components/Spinner";
// import moment from "moment";
// import { socket } from "../../API";
// import { useNavigate } from "react-router-dom";
// import sound from "./notification.mp3";

// const Message = () => {
// 	const [screenWidth, setScreenWidth] = useState(window.innerWidth);

// 	const [data, setData] = useState<any>([]);
// 	const [selectedChat, setSelectedChat] = useState(data[0]);
// 	const [isLoading, setIsLoading] = useState(false);
// 	const [messages, setMessages] = useState<any>([]);
// 	const [newMsg, setNewMsg] = useState("");

// 	const lastMessageRef = useRef<HTMLDivElement>(null);
// 	const audioRef: any = useRef<HTMLAudioElement>(null);
// 	function playAudio() {
// 		audioRef.current.play();
// 	}
// 	useEffect(() => {
// 		const handleResize: any = () => {
// 			setScreenWidth(window.innerWidth);
// 		};
// 		if (lastMessageRef.current) {
// 			lastMessageRef?.current.scrollIntoView({ behavior: "smooth" });
// 		}
// 		window.addEventListener("resize", handleResize);
// 		return () => {
// 			window.removeEventListener("resize", handleResize);
// 		};
// 	}, []);
// 	useEffect(() => {
// 		// Scroll to the bottom of the chat body
// 		if (lastMessageRef.current) {
// 			lastMessageRef?.current.scrollIntoView({ behavior: "smooth" });
// 		}
// 	}, [messages]); // Scroll when messages state updates

// 	useEffect(() => {
// 		async function fetchData() {
// 			await userOBJ
// 				.get_all_chats(getStoredClientUser()?.userID)
// 				.then((resp: any) => {
// 					if (resp.status) {
// 						setData(resp.populatedResults);
// 						socket.emit("chats", resp?.populatedResults);
// 						setSelectedChat(resp?.populatedResults[0]);
// 					}
// 					if (screenWidth > 600 && resp.populatedResults[0]) {
// 						socket.emit(
// 							"messageRead",
// 							resp.populatedResults[0]._id,
// 							getStoredClientUser()?.userID
// 						);
// 					}
// 				});
// 		}
// 		fetchData();

// 		return () => {};
// 	}, []); // Removed isLoading and messages from dependencies

// 	useEffect(() => {
// 		// Listen for incoming messages
// 		socket.on("message", (message) => {
// 			setMessages((prevMessages: any) => [...prevMessages, message]);
// 			if (lastMessageRef.current) {
// 				console.log(lastMessageRef);

// 				lastMessageRef.current.scrollIntoView({ behavior: "smooth" });
// 			}

// 			if (message.sender != getStoredClientUser()?.userID) {
// 				playAudio();
// 			}
// 		});

// 		// Clean up event listeners
// 		return () => {
// 			socket.off("message");
// 		};
// 	}, []);

// 	useEffect(() => {
// 		async function fetchData() {
// 			await userOBJ.get_all_messages(selectedChat?._id).then((resp: any) => {
// 				if (resp.status) {
// 					setMessages(resp.messages);
// 				}
// 			});
// 		}
// 		if (selectedChat) {
// 			fetchData();
// 		}

// 		return () => {};
// 	}, [selectedChat]);
// 	const nav = useNavigate();
// 	const handleMessageSubmit = async (e: any) => {
// 		e.preventDefault();
// 		let payload = {
// 			chat: selectedChat._id,
// 			content: newMsg,
// 			type: "text",
// 			sender: getStoredClientUser()?.userID,
// 		};
// 		setIsLoading(true);
// 		await userOBJ.send_message(payload).then((res: any) => {
// 			setNewMsg("");

// 			if (res) {
// 				if (res?.status === true) {
// 					setIsLoading(false);
// 					socket.emit("sendMessage", payload);
// 					setMessages([...messages, payload]);
// 				} else {
// 					toast.error(res?.message);
// 					setIsLoading(false);
// 				}
// 			} else {
// 				toast.error(res?.message);
// 				setIsLoading(false);
// 			}
// 		});
// 	};
// 	const handleChat = (data: any) => {
// 		setSelectedChat(data);
// 		socket.emit("messageRead", data?._id, getStoredClientUser()?.userID);
// 		if (screenWidth <= 600) {
// 			nav("/message/me/2");
// 		}
// 	};
// 	return (
// 		<UserLayout>
// 			<Container>
// 				<Wrapper isMobile={screenWidth <= 600}>
// 					<FirstSection>
// 						<Flex>
// 							<h1>All Messages {`(${data?.length})` ?? ""}</h1>
// 							<img src={Pen} alt="" />
// 						</Flex>
// 						{data?.map((chat: any, i: number) => {
// 							return (
// 								<ChatContainer key={i} onClick={() => handleChat(chat)}>
// 									{/* <div className="rel"> */}
// 									<img
// 										src={
// 											getFriend(chat.users, getStoredClientUser()?.userID)
// 												?.photo
// 										}
// 										alt="..."
// 									/>
// 									<div
// 										className="indicator"
// 										style={{ background: `green` }}
// 									></div>
// 									{/* </div> */}
// 									<section className="">
// 										<div className="header">
// 											<h1>
// 												{
// 													getFriend(chat.users, getStoredClientUser()?.userID)
// 														?.fullname
// 												}
// 											</h1>
// 											<span>
// 												<p>{moment(chat.updatedAt).format("ddd mm, yy")}</p>
// 												<p>{moment(chat.updatedAt).format("h:mm A")}</p>
// 											</span>
// 										</div>
// 										<div
// 											style={{
// 												display: "flex",
// 												justifyContent: "space-between",
// 											}}
// 										>
// 											<p
// 												className="text"
// 												style={{
// 													fontWeight:
// 														chat?.latestMessage?.readBy?.length == 0
// 															? 800
// 															: "inherit",
// 												}}
// 											>
// 												{chat?.latestMessage?.content ?? ""}
// 											</p>
// 											{chat?.latestMessage?.readBy?.length == 0 && (
// 												<p
// 													style={{
// 														display: "flex",
// 														justifyContent: "center",
// 														alignItems: "center",
// 														width: 8,
// 														height: 8,
// 														borderRadius: "50%",
// 														background: "green",
// 														color: "#fff",
// 														fontSize: 12,
// 													}}
// 												></p>
// 											)}
// 										</div>
// 									</section>
// 								</ChatContainer>
// 							);
// 						})}
// 					</FirstSection>
// 					{screenWidth > 600 && (
// 						<SecondSection>
// 							<MessageHeader>
// 								<img
// 									src={
// 										getFriend(
// 											selectedChat?.users,
// 											getStoredClientUser()?.userID
// 										)?.photo
// 									}
// 									alt=""
// 								/>
// 								<h1>
// 									{
// 										getFriend(
// 											selectedChat?.users,
// 											getStoredClientUser()?.userID
// 										)?.fullname
// 									}
// 								</h1>
// 							</MessageHeader>
// 							<MessageBody>
// 								<form onSubmit={handleMessageSubmit}>
// 									<input
// 										type="text"
// 										placeholder="Type your message here"
// 										value={newMsg}
// 										onChange={(e: any) => setNewMsg(e.target.value)}
// 									/>
// 									{isLoading ? (
// 										<Spinner isLoading={isLoading} />
// 									) : (
// 										<FaCircleArrowRight
// 											color="rgb(241, 94, 56)"
// 											size={40}
// 											onClick={handleMessageSubmit}
// 										/>
// 									)}
// 								</form>
// 								<div className="content" ref={lastMessageRef}>
// 									<audio ref={audioRef} src={sound} className="hidden" />
// 									{messages?.map((msg: any, i: any) => {
// 										return (
// 											<aside>
// 												{getStoredClientUser()?.userID !== msg?.sender?._id ? (
// 													<div style={{ display: "flex", gap: 10 }} key={i}>
// 														<img src={msg?.sender?.photo} alt="" />
// 														<div style={{ marginTop: -10 }}>
// 															<div className={`other`}>{msg.content}</div>
// 															<p style={{ marginTop: 8 }}>
// 																{moment(msg.createdAt).format(
// 																	"dddd [at] h:mm A"
// 																)}
// 															</p>
// 														</div>
// 													</div>
// 												) : (
// 													<div
// 														style={{
// 															marginTop: -10,
// 															width: "100%",
// 															marginLeft: "auto",
// 														}}
// 													>
// 														<div className={`self`}>{msg.content}</div>
// 														<p style={{ marginTop: 8, marginLeft: "auto" }}>
// 															{moment(msg.createdAt).format("dddd [at] h:mm A")}
// 														</p>
// 													</div>
// 												)}
// 											</aside>
// 										);
// 									})}
// 									{messages?.length === 0 && (
// 										<div
// 											style={{
// 												textAlign: "center",
// 												display: "flex",
// 												justifyContent: "center",
// 												width: "100%",
// 											}}
// 										>
// 											<p>No messages yet</p>
// 										</div>
// 									)}
// 								</div>
// 							</MessageBody>
// 						</SecondSection>
// 					)}
// 				</Wrapper>
// 			</Container>
// 		</UserLayout>
// 	);
// };

// export default Message;
import UserLayout from "../../Layouts/UserLayout/UserLayout";
import {
	ChatContainer,
	Container,
	FirstSection,
	Flex,
	MessageBody,
	MessageHeader,
	SecondSection,
	Wrapper,
} from "./Styles";
import Pen from "../../Assets/icons/pen-square.svg";
import { FaCircleArrowRight } from "react-icons/fa6";
import { useEffect, useRef, useState } from "react";
import userOBJ from "../../classes/user.class";
import { getStoredClientUser } from "../../utils/LS";
import { getFriend } from "../../utils/some";
import { toast } from "react-toastify";
import Spinner from "../../Components/Spinner";
import moment from "moment";
import { socket } from "../../API";
import { useLocation, useNavigate } from "react-router-dom";
import sound from "./notification.mp3";

const Message = () => {
	const [screenWidth, setScreenWidth] = useState(window.innerWidth);

	const [data, setData] = useState<any>([]);
	const [selectedChat, setSelectedChat] = useState<any>(null); // Initialize selectedChat with null
	const [isLoading, setIsLoading] = useState(false);
	const [msgLoading, setMsgLoading] = useState(false);
	const [messages, setMessages] = useState<any>([]);
	const [newMsg, setNewMsg] = useState("");

	const lastMessageRef = useRef<HTMLDivElement>(null);
	const audioRef: any = useRef<HTMLAudioElement>(null);
	let { state } = useLocation();
	function playAudio() {
		audioRef.current.play();
	}

	useEffect(() => {
		const handleResize: any = () => {
			setScreenWidth(window.innerWidth);
		};
		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	useEffect(() => {
		// Scroll to the bottom of the chat body when messages state updates or on initial load
		if (lastMessageRef.current) {
			lastMessageRef?.current.scrollIntoView({ behavior: "smooth" });
		}
	}, [messages, selectedChat]);

	useEffect(() => {
		async function fetchData() {
			await userOBJ
				.get_all_chats(getStoredClientUser()?.userID)
				.then((resp: any) => {
					if (resp.status) {
						setData(resp.populatedResults);
						socket.emit("chats", resp?.populatedResults);
						setSelectedChat(resp?.populatedResults[0]); // Select the first chat initially
					}
					if (screenWidth > 600 && resp.populatedResults[0]) {
						socket.emit(
							"messageRead",
							resp.populatedResults[0]._id,
							getStoredClientUser()?.userID
						);
					}
				});
		}
		fetchData();
	}, [screenWidth]); // Fetch data when screenWidth changes

	useEffect(() => {
		async function fetchData() {
			await userOBJ
				.get_all_chats(getStoredClientUser()?.userID)
				.then((resp: any) => {
					if (resp.status) {
						socket.emit("chats", resp?.populatedResults);
						setData(resp.populatedResults);
					}
					if (screenWidth > 600 && selectedChat) {
						socket.emit(
							"messageRead",
							resp.populatedResults[0]._id,
							getStoredClientUser()?.userID
						);
					}
				});
		}
		fetchData();
	}, [messages]); // Fetch data when screenWidth changes
	const handleMessageSubmit = async (e: any) => {
		e.preventDefault();
		if (!selectedChat) return; // If no chat is selected, do nothing
		let payload = {
			chat: selectedChat._id,
			content: newMsg,
			type: "text",
			sender: getStoredClientUser()?.userID,
		};
		setIsLoading(true);
		await userOBJ.send_message(payload).then((res: any) => {
			// console.log(res);
			setNewMsg("");
			setIsLoading(false);
			if (res && res.status === true) {
				socket.emit("sendMessage", payload);
				setMessages([...messages, res.isMessage]); // Update messages state with the sent message
			} else {
				toast.error(res?.message);
			}
		});
	};

	console.log(state);

	const handleTemplateMsg = async (e: any) => {
		e.preventDefault();
		if (!selectedChat) return; // If no chat is selected, do nothing

		let payload = {
			chat: selectedChat._id,
			content: `Hi I am ${
				getStoredClientUser().fullname
			} and you just accepted my application on your request of "${
				state?.requestId?.subject
			}" schedule to hold ${moment(state?.requestId?.schedule).format(
				"l LT"
			)}, i would love to ask what platform you would like to have the meeting from the following "Google meet, Microsoft team or zoom"`,
			type: "text",
			sender: getStoredClientUser()?.userID,
		};
		setIsLoading(true);
		await userOBJ.send_message(payload).then((res: any) => {
			setNewMsg("");
			state = null;
			setIsLoading(false);
			if (res && res.status === true) {
				socket.emit("sendMessage", payload);
				setMessages([...messages, res.isMessage]); // Update messages state with the sent message
			} else {
				toast.error(res?.message);
			}
		});
	};

	useEffect(() => {
		// This will trigger handleTemplateMsg on component mount
		if (state?._id) {
			handleTemplateMsg({ preventDefault: () => {} });
		}
	}, []);
	useEffect(() => {
		// Listen for incoming messages
		socket.on("message", (message) => {
			console.log(message);
			getStoredClientUser()?.userID !== message.sender &&
				setMessages((prevMessages: any) => [...prevMessages, message]);
			playAudio(); // Play audio for incoming messages

			// Ensure the last message is scrolled into view
		});

		// Clean up event listeners
		return () => {
			socket.off("message");
		};
	}, []);
	useEffect(() => {
		setMsgLoading(true);
		async function fetchData() {
			await userOBJ.get_all_messages(selectedChat?._id).then((resp: any) => {
				if (resp.status) {
					setMessages(resp.messages);
				}
			});
		}
		if (selectedChat) {
			fetchData();
			setMsgLoading(false);
		}

		return () => {};
	}, [selectedChat]);
	const nav = useNavigate();

	const handleChat = (data: any) => {
		setSelectedChat(data);
		socket.emit("messageRead", data?._id, getStoredClientUser()?.userID);
		if (screenWidth <= 600) {
			nav("/message/me/2");
		}
	};

	return (
		<UserLayout>
			<Container>
				<Wrapper isMobile={screenWidth <= 600}>
					<FirstSection>
						<Flex>
							<h1>All Chats ({data?.length ?? ""})</h1>
							<img src={Pen} alt="" />
						</Flex>
						{data?.map((chat: any, i: number) => {
							return (
								<ChatContainer key={i} onClick={() => handleChat(chat)}>
									<img
										src={
											getFriend(chat.users, getStoredClientUser()?.userID)
												?.photo
										}
										alt="..."
									/>
									{chat?.latestMessage?.sender?._id !==
										getStoredClientUser()?.userID && (
										<div
											className="indicator"
											style={{ background: `green` }}
										></div>
									)}
									<section className="">
										<div className="header">
											<h1>
												{
													getFriend(chat.users, getStoredClientUser()?.userID)
														?.fullname
												}
											</h1>
											<span>
												<p>{moment(chat.updatedAt).format("ddd mm, yy")}</p>
												<p>{moment(chat.updatedAt).format("h:mm A")}</p>
											</span>
										</div>
										<div
											style={{
												display: "flex",
												justifyContent: "space-between",
											}}
										>
											<p
												className="text"
												style={{
													fontWeight:
														chat?.latestMessage?.readBy?.length === 0 &&
														chat?.latestMessgage?.sender?._id !==
															getStoredClientUser()?.userID
															? 800
															: "inherit",
												}}
											>
												{chat?.latestMessage?.content ?? ""}
											</p>
											{chat?.latestMessage?.readBy?.length === 0 && (
												<p
													style={{
														display: "flex",
														justifyContent: "center",
														alignItems: "center",
														width: 8,
														height: 8,
														borderRadius: "50%",
														background: "green",
														color: "#fff",
														fontSize: 12,
													}}
												></p>
											)}
										</div>
									</section>
								</ChatContainer>
							);
						})}
					</FirstSection>
					{screenWidth > 600 && (
						<SecondSection>
							<MessageHeader>
								<img
									src={
										getFriend(
											selectedChat?.users,
											getStoredClientUser()?.userID
										)?.photo
									}
									alt=""
								/>
								<h1>
									{
										getFriend(
											selectedChat?.users,
											getStoredClientUser()?.userID
										)?.fullname
									}
								</h1>
							</MessageHeader>
							<MessageBody>
								{msgLoading ? (
									<div
										style={{
											display: "flex",
											gap: "10px",
											alignItems: "center",
											justifyContent: "center",
											height: "80vh",
										}}
									>
										Loading
										<Spinner isLoading={msgLoading} />
									</div>
								) : (
									<>
										<form onSubmit={handleMessageSubmit}>
											<input
												type="text"
												placeholder="Type your message here"
												value={newMsg}
												onChange={(e: any) => setNewMsg(e.target.value)}
											/>
											{isLoading ? (
												<Spinner isLoading={isLoading} />
											) : (
												<FaCircleArrowRight
													color="rgb(241, 94, 56)"
													size={40}
													onClick={handleMessageSubmit}
												/>
											)}
										</form>
										<div className="content">
											<audio ref={audioRef} src={sound} className="hidden" />
											{messages?.map((msg: any, i: any) => {
												return (
													<aside key={i}>
														{getStoredClientUser()?.userID !==
														msg?.sender?._id ? (
															<div style={{ display: "flex", gap: 10 }}>
																<img src={msg?.sender?.photo} alt="" />
																<div style={{ marginTop: -10 }}>
																	<div className={`other`}>{msg.content}</div>
																	<p style={{ marginTop: 8 }}>
																		{moment(msg.createdAt).format(
																			"dddd [at] h:mm A"
																		)}
																	</p>
																</div>
															</div>
														) : (
															<div
																style={{
																	marginTop: -10,
																	width: "100%",
																	marginLeft: "auto",
																}}
															>
																<div className={`self`}>{msg.content}</div>
																<p style={{ marginTop: 8, marginLeft: "auto" }}>
																	{moment(msg.createdAt).format(
																		"dddd [at] h:mm A"
																	)}
																</p>
															</div>
														)}
													</aside>
												);
											})}
											{messages?.length === 0 && (
												<div
													style={{
														textAlign: "center",
														display: "flex",
														justifyContent: "center",
														width: "100%",
													}}
												>
													<p>No messages yet</p>
												</div>
											)}
											<div ref={lastMessageRef} />
										</div>
									</>
								)}
							</MessageBody>
						</SecondSection>
					)}
				</Wrapper>
			</Container>
		</UserLayout>
	);
};

export default Message;
