import React, { useState } from "react";
import CurrencyInput from "react-currency-input-field";
import { PrimaryBtn } from "../../Components/Button";

export default function CouseAmount({
  setcurrentStep,
  setstepData,
  stepData,
}: any) {
  const [amount, setamount] = useState<any>("");

  const handleProceed = () => {
    setstepData([...stepData, amount]);
    setcurrentStep(4);
  };
  return (
    <div>
      <div className=" py-2 text-gray-600 pb-40">
        <div className="border-l-4 border-orange-500 pl-2">
          <h2 className="text-2xl">Course charges</h2>
          <p className="font-light">Set subscription amount for this course</p>
        </div>

        <div className="mt-14 lg:w-6/12 m-auto">
          <div className="mt-14 w-full m-auto border border-slate-200 rounded-md">
            <CurrencyInput
              id="input-example"
              name="input-name"
              placeholder="₦0,00"
              defaultValue={amount}
              decimalsLimit={2}
              maxLength={4}
              prefix="₦"
              onValueChange={(value, name) => setamount(value)}
              className="h-28 flex justify-center text-gray-400 px-10 w-full text-4xl font-bold outline-none"
            />
          </div>
          {amount && (
            <p className="py-5 text-sm ">
              You will be paid{" "}
              <span className="text-green-600 font-semibold">
                NGN{parseInt(amount) - parseInt(amount) / 10}{" "}
              </span>
              per Attendee
            </p>
          )}
          <div className="mt-6">
            <PrimaryBtn
              isDisabled={amount ? false : true}
              title="Proceed"
              btnType="submit"
              onBtnClick={() => handleProceed()}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
