import styled from "styled-components";
import { AppColors } from "../../utils/constants";
import { BreakPoints } from "../../utils/breakpoints";

export const PageLayout = styled.div`
  background: ${AppColors.primary};
  position: relative;
  color: #fff;
  height: 100vh;
  width: 100%;
  // margin: 50px auto;
  overflow-y: scroll;
  /* border: 2px solid ${AppColors.brandRed}; */

  @media (${BreakPoints.xs}) {
    padding: 1rem;
    margin: auto;
  }

  @media (${BreakPoints.large}) {
    padding: 1rem 8rem;
  }
`;

export const PageHeader = styled.div`
  width: 100%;
  margin: 2rem auto;
  padding: 0.6rem;
  background: rgb(241, 94, 56);

  h2 {
    font-size: 1.2rem;
  }

  @media (${BreakPoints.xs}) {
    width: 100%;
  }
  @media (${BreakPoints.small}) {
  }
  @media (${BreakPoints.large}) {
    width: 80%;
  }
`;

export const AddBtn = styled.button`
  width: 100px;
  border: unset;
  outline: unset;
  padding: 10px;
  color: #fff;
  border-radius: 10px;
  background: rgb(241, 94, 56);
`;
export const ContentContainer = styled.div`
  width: 1005;
  background-color: #fff;
  height: 78vh;
  overflow-y: scroll;
  padding: 2rem;
  margin-top: 2rem;
  border-radius: 0.2rem;
`;

export const Flex = styled.div`
  display: flex;

  label {
    gap: 20px;
    min-width: 300px;
    height: fit-content !important;
  }

  @media screen and (max-width: 550px) {
    flex-wrap: wrap;
  }
`;

export const Box = styled.div``;

export const TabPanel = styled.button`
  background: transparent;
  border: 0;
  outline: none;
  font-family: "Manrope", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #575757;
`;
export const Identifier = styled.div`
  background: #848484;
  color: #fff;
  width: fit-content;
  padding: 10px;
  border-radius: 50px;
  position: relative;

  span {
    cursor: pointer;
    padding-left: 5px;
  }
`;
export const Grid = styled.div`
  display: grid;
  padding: 2rem;
  /* border-radius: 1rem; */
  margin-top: -2rem;
  background: #fff;
  grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
  grid-gap: 70px;

  @media (${BreakPoints.xs}) {
    padding: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
  /* @media (${BreakPoints.small}) {
    grid-template-columns: 1fr;
  }
  @media (${BreakPoints.medium}) {
    grid-template-columns: 1fr;
  } */
  @media (${BreakPoints.large}) {
    width: 80%;
    margin: auto;
    margin-top: -2rem;
  }
`;
export const Image = styled.img`
  @media (${BreakPoints.xs}) {
    display: none;
  }
  @media (${BreakPoints.small}) {
    display: none;
  }
  @media (${BreakPoints.medium}) {
    display: none;
  }
  @media (${BreakPoints.large}) {
    display: block;
  }
`;

export const ActionButton = styled.button`
  border: 0;
  outline: none;
  width: 80px;
  height: 30px;
  margin: 5px;
  border-radius: 4px;
  @media (${BreakPoints.xs}) {
    width: 70px;
    height: 30px;
  }
  @media (${BreakPoints.small}) {
    width: 90px;
    height: 40px;
  }
  @media (${BreakPoints.large}) {
    width: 80px;
    height: 30px;
  }
`;

export const Input = styled.input`
  width: 100%;
  padding: 10px;
  height: 50px;
  border-radius: 5px;
  border: 2px solid #eee;
  outline-style: none;
`;
export const Select = styled.select`
  width: 100%;
  padding: 10px;
  height: 50px;
  border-radius: 5px;
  border: 2px solid #eee;
`;

export const TextArea = styled.textarea`
  width: 100%;
  padding: 10px;
  height: 50px;
  border-radius: 5px;
  border: 0;
  outline: none;
`;

export const UserAvatar = styled.div`
  vertical-align: middle;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  position: relative;

  img {
    width: 50px;
    height: 50px;
    object-fit: cover;
    position: absolute;
    border-radius: 100%;
  }
`;
