import styled from "styled-components";
import { AppColors } from "../../utils/constants";
export const PageLayout = styled.div``;

export const CardWrapper = styled.div`
  width: 70%;
  height: fit-content;
  margin: 4rem auto;
  /* background-color: rgba(255, 252, 251, 0.8); */
  padding: 20px;
  @media (max-width: 700px) {
    width: 85%;
  }
  @media (max-width: 500px) {
    width: 95%;
  }
`;
export const Card = styled.div`
  width: 100%;
  min-height: 277px;
  background: #ffffff;
  border: 0.2px solid #848484;
  border-radius: 10px;
  padding: 25px 40px;
  margin: 30px auto 10px auto;

  @media (max-width: 450px) {
    padding: 10px;
    // margin-top: 90px;
  }
  p {
    margin-left: 8px;
    font-weight: 400;
    font-size: 12px;
  }
  .flexy {
    display: flex;
    // align-items: center;
    font-weight: 400;
    font-size: 16px;
    color: #292929;
    gap: 30px;
    margin: 10px 0;
    .img_container{
      width:50%;
      @media (max-width:780px){
        width:100%
      }
    }
   
  }

  div {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 121.19%;
    color: #292929;
    margin: 15px 0 10px 0;
  }
`;

export const LeftAligned = styled.section`
  display: flex;
  // justify-content: end;
  align-items: center;
  margin-top: 38px;

  @media (max-width: 450px) {
    justify-content: space-between;
    // flex-wrap: wrap;
    gap: 0px;
  }
  @media (max-width: 350px) {
    // justify-content: center;
    // flex-wrap: wrap;
    gap: 10px;
  }
`;
export const ButtonLeft = styled.button`
  outline: none;
  border: none;
  cursor: pointer;
  padding: 0px 20px;
  left: calc(50% - 258px / 2 + 261px);
  top: 340px;
  background: transparent;
  border-radius: 4px;
  color: #f15e38;
  font-weight: 500;
  font-size: 14px;
  @media (max-width: 450px) {
    font-size: 10px;
  }
`;
export const ButtonRight = styled.button`
  outline: none;
  border: none;
  cursor: pointer;
  padding: 8px 0;
  width: 102px;
  height: fit-content;
  left: calc(50% - 258px / 2 + 261px);
  top: 340px;
  background: #f15e38;
  border-radius: 4px;
  /* color: #fff; */
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: rgba(255, 252, 251, 0.9);
  display: flex;
  justify-content: center;

  @media (max-width: 450px) {
    padding: 8px;
    font-size: 10px;
    width: fit-content;
    color: rgb(255, 255, 255);
  }
`;
export const ShowMore = styled.button`
  height: unset;
  // padding: 0 4rem;
  color: #f15e38;
  background: transparent;
  outline-style: none;
  font-weight: 500;
  font-size: 12px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 7px;
`;
