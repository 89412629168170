import { useState } from "react";
import moment from "moment";
import { useLocation, useNavigate, useRoutes } from "react-router-dom";
import {
	Card,
	CardButton,
	CardButtonContainer,
	CardContent,
	CardDate,
	CardDescription,
	CardHeader,
	CardLang,
	CardStatus,
	CardWrapper,
	Download,
	Schedule,
	SubjectCont,
	ShowMore,
	Break,
	Ribbon,
	RibbonStatus,
	PageLayout,
} from "./Styles";
import { getStoredClientUser } from "../../utils/LS";
import { formatDateAgo } from "../../utils/some";
import Modal from "../../Components/SettlementModal";
import UserLayout from "../../Layouts/UserLayout/UserLayout";

type RequestPropT = {
	data: {
		date: any;
		isOpen: boolean;
		isClass: boolean;
		subject: string;
		language: any;
		description: any;
		duration: number;
		applicants: any;
		createdAt: string;
		schedule: string;
		file?: string;
		merithubTutorID: string;
		_id: string;
	};
};

// const data: any = {
// 	date: "12,2020",
// 	subject: "English",
// };
export default function Settlement() {
	const { userType } = getStoredClientUser();
	const [showMOre, setShowMOre] = useState(false);
	const [popUp, setPopUp] = useState(false);
	const navigate = useNavigate();
	const { state: data } = useLocation();

	const clickHandler = () => {
		setPopUp(!popUp);
	};

	const scheduleTime = data?.schedule;
	// console.log(data);
	return (
		<UserLayout>
			<PageLayout>
				<div>
					<Card>
						<Ribbon status={data?.isOpen}>
							<RibbonStatus status={data?.isOpen}>
								{data?.isOpen ? "Open" : "Closed"}
							</RibbonStatus>
						</Ribbon>
						<CardHeader>
							<CardStatus isActive={data?.isOpen ? true : false}>
								{/* {data?.isOpen ? "Open" : "Closed"} */}
							</CardStatus>
							<CardDate>
								<em>Posted {formatDateAgo(data?.createdAt)}</em>
							</CardDate>
						</CardHeader>
						<hr
							style={{ border: "0.55px solid #E5E7E8", marginBottom: "1rem" }}
						/>
						<CardContent>
							<SubjectCont>
								Subject:<span>{data?.subject}</span>
							</SubjectCont>

							<Schedule>
								Date:
								{/* @ts-ignore */}
								<span>
									{/* {moment().format(scheduleTime)}{" "} */}
									{moment(scheduleTime).format("l")}
								</span>
							</Schedule>
							<Schedule>
								Time:
								{/* @ts-ignore */}
								<span>
									{/* {moment().format(scheduleTime)}{" "} */}
									{moment(scheduleTime).format("LT")}
								</span>
							</Schedule>
							<Schedule>
								Duration:
								{/* @ts-ignore */}
								<span>
									{/* {moment().format(scheduleTime)}{" "} */}
									{data?.duration || 1} hrs
								</span>
							</Schedule>
							<CardDescription>
								{data?.description?.length > 80 && !showMOre
									? data?.description.slice(0, 200) + "..."
									: data?.description}
							</CardDescription>
							{data?.description?.length > 80 && (
								<ShowMore onClick={() => setShowMOre(!showMOre)}>
									{showMOre ? "Show less" : "Show More"}
								</ShowMore>
							)}
							<Break>
								<CardLang>
									Language: <span>{data?.language || "English"}</span>
								</CardLang>
								<CardWrapper>
									<Download>
										{data?.isOpen && userType !== "Student" && data?.file && (
											<a
												href={data.file}
												style={{ color: "#e74c3c" }}
												download
												title="Download file"
											>
												<i className="fa fa-download" aria-hidden="true"></i>
											</a>
										)}
									</Download>
									<CardButtonContainer>
										<CardButton
											onClick={() => setPopUp(!popUp)}
											disabled={data?.settlement}
										>
											Settlement
										</CardButton>
									</CardButtonContainer>
								</CardWrapper>
							</Break>
						</CardContent>
					</Card>
				</div>
				{popUp && <Modal data={data} />}
			</PageLayout>
		</UserLayout>
	);
}
