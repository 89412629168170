import React from "react";
import { Link } from "react-router-dom";
import { ContentArea, FAB, LayoutHead, PageLayout } from "./Styles";
import DashboardIcon from "../../Assets/icons/DashboardIcon.svg";
import DashboardHeader from "../DBHeader";

export default function UserLayout({ children, pagename }: any) {
  return (
		<div>
			<PageLayout>
				<Link to="/">
					<FAB>
						<img src={DashboardIcon} alt="" />
					</FAB>
				</Link>
				<LayoutHead>
					<DashboardHeader pagename={pagename} />
				</LayoutHead>
				{/* <div
          className=" mt-24 bg-white py-4 cursor-pointer w-full px-4 lg:w-[80%] m-auto flex gap-2 items-center z-50"
          // @ts-ignore
          onClick={() => window.history.back()}
        >
          <svg
            width="16"
            height="15"
            viewBox="0 0 16 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15 6.50064H3.14L6.77 2.14064C6.93974 1.93642 7.0214 1.67314 6.99702 1.40872C6.97264 1.14429 6.84422 0.90038 6.64 0.730642C6.43578 0.560903 6.1725 0.479241 5.90808 0.503621C5.64365 0.528001 5.39974 0.656425 5.23 0.860642L0.23 6.86064C0.196361 6.90837 0.166279 6.9585 0.14 7.01064C0.14 7.06064 0.14 7.09064 0.0700002 7.14064C0.0246737 7.2553 0.000941121 7.37735 0 7.50064C0.000941121 7.62393 0.0246737 7.74598 0.0700002 7.86064C0.0700002 7.91064 0.0699999 7.94064 0.14 7.99064C0.166279 8.04278 0.196361 8.09292 0.23 8.14064L5.23 14.1406C5.32402 14.2535 5.44176 14.3443 5.57485 14.4065C5.70793 14.4687 5.85309 14.5009 6 14.5006C6.23365 14.5011 6.46009 14.4197 6.64 14.2706C6.74126 14.1867 6.82496 14.0836 6.88631 13.9672C6.94766 13.8509 6.98546 13.7236 6.99754 13.5926C7.00961 13.4616 6.99573 13.3296 6.95669 13.204C6.91764 13.0784 6.8542 12.9617 6.77 12.8606L3.14 8.50064H15C15.2652 8.50064 15.5196 8.39528 15.7071 8.20775C15.8946 8.02021 16 7.76586 16 7.50064C16 7.23543 15.8946 6.98107 15.7071 6.79354C15.5196 6.606 15.2652 6.50064 15 6.50064Z"
              fill="#1A1A1A"
            />
          </svg>
          back
        </div> */}
				<ContentArea>
          
          {children}
          </ContentArea>
			</PageLayout>
		</div>
	);
}
