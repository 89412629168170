import styled from "styled-components";
import { BreakPoints } from "../../utils/breakpoints";
import { AppColors } from "../../utils/constants";

type CardStatusPropT = {
	isActive: boolean;
};

type RibbonT = {
	status: boolean;
};

export const Ribbon = styled.div<RibbonT>`
	width: 120px;
	height: 26px;
	background: ${(prop) => (prop.status ? "#F6FFFA" : "#FFF5F5")};
	color: ${(prop) => (prop.status ? "#71D88D" : "#ED5C49")};
	padding: 8px;
	border-radius: 4px;
	position: absolute;
	// top: -2.2rem;
	// left: -3rem;
	// right: -1rem;
	@media (max-width: 490px) {
		width: 70px;
		font-size: 12px;
	}

	// transform: rotate(-45deg);
`;

export const RibbonStatus = styled.h4<RibbonT>`
	// position: absolute;
	// color: #fff;
	// top: 3.5rem;
	// left: ${(prop) => (prop.status ? "2.2rem" : "1.88rem")};
	margin-top: -5px;
	text-align: center;
	@media (max-width: 490px) {
		margin-top: -3px;
	}
`;
export const Card = styled.div`
	width: 100%;
	position: relative;
	font-size: 1.2rem;
	font-style: normal;
	display: block;
	font-weight: 400;
	background: #fff;
	border-radius: 5px;
	margin-bottom: 1.4rem;
	height: fit-content;
	overflow: hidden;

	box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
	top: 0;
	transition: all ease 0.6s;
	:hover {
		top: -0.6rem;
	}
	@media (${BreakPoints.xs}) {
		width: 100%;
		padding: 1rem;
		font-size: 1rem;
	}
	@media (${BreakPoints.small}) {
		width: 100%;
		padding: 1rem;
		font-size: 1rem;
	}
	@media (${BreakPoints.large}) {
		width: 100%;
		padding: 2rem;
	}
`;

export const CardHeader = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	height: 20px;
	font-size: 1rem;
	margin-bottom: 1rem;

	@media (${BreakPoints.xs}) {
		font-size: 0.8rem;
	}
	@media (${BreakPoints.large}) {
	}
`;

export const CardStatus = styled.span<CardStatusPropT>`
	display: inline-block;
	width: 20%;
	color: ${(prop) => (prop.isActive ? "#27AE60" : AppColors.brandRed)};
`;

export const CardDate = styled.span`
	display: inline-block;
	display: flex;
	justify-content: end;
	width: 45%;
	font-size: 0.9rem;
	@media (${BreakPoints.xs}) {
		width: unset;
	}
`;

export const CardContent = styled.div`
	margin-top: 1.4rem;
	// height: 140px;
`;

export const SubjectCont = styled.div`
	width: 100%;
	color: #848484;
	font-size: 1rem;

	span {
		color: ${AppColors.brandBlack};
		display: inline-block;
		margin-left: 0.2rem;
		font-weight: 600;
	}
`;

export const Schedule = styled.div`
	position: relative;
	margin: 10px 0;
	padding: 0.4 0rem;
	font-size: 1rem;
	color: #848484;

	span {
		color: ${AppColors.brandBlack};
		display: inline-block;
		margin-left: 0.2rem;
		font-weight: 600;
	}
`;

export const CardDescription = styled.div`
	// width: 80%;
	margin-top: 1rem;
	color: #292929;
	font-weight: 300;
	line-height: 1.8rem;
	@media (${BreakPoints.xs}) {
		width: 100%;
		font-size: 1rem;
	}
`;

export const CardLang = styled.div`
	width: 100%;
	color: #292929;
	font-size: 1rem;
	font-weight: 300;
	margin-top: 1rem;
	span {
		color: ${AppColors.brandBlack};
		display: inline-block;
		margin-left: 0.2rem;
		font-weight: 450;
		font-size: 0.88rem;
	}
`;
export const Break = styled.div`
	width: 100%;
	display: grid;
	grid-template-columns: auto auto;
	height: fit-content;
	align-items: start;
	justify-items: end;
	gap: 1em;

	@media (${BreakPoints.xs}) {
		grid-template-columns: auto;
	}
`;

export const CardWrapper = styled.div`
	margin-top: 1rem;
	width: 100%;
	display: flex;
	justify-content: end;
	align-items: center;
	height: 50px;
	gap: 20px;
`;
export const Download = styled.div`
	width: 20%;
	height: 19px;
	margin-top: 22px;
	display: flex;
	align-items: center;
	justify-content: flex-end;

	@media (${BreakPoints.xs}) {
		justify-content: flex-start;
	}
`;

export const CardButtonContainer = styled.div`
	margin-top: 1rem;
	width: 80%;
	display: flex;
	justify-content: end;
	align-items: center;
	height: 50px;
	gap: 20px;
	/* padding: 0 2rem; */

	@media (max-width: 490px) {
		margin-top: 0;
		height: unset;
		/* justify-content: space-between; */
	}
`;

export const Interactions = styled.div`
	width: 15%;
	display: flex;
	align-items: center;
	gap: 0.4rem;
	cursor: pointer;
	img {
		width: 14px;
	}
	span {
		font-size: 0.8rem;
	}

	@media (min-width: 260px) and (max-width: 500px) {
		width: 20%;
	}
`;

export const CardButton = styled.button`
	height: 40px;
	padding: 0 2rem;
	color: #fff;
	font-weight: 700;
	background: ${AppColors.brandRed};
	outline-style: none;
	border: none;
	border-radius: 5px;
	cursor: pointer;
	:disabled {
		background: #ccc;
		color: #fff;
		cursor: not-allowed;
	}
	@media (${BreakPoints.xs}) {
		padding: 0 1rem;
	}
`;

export const ShowMore = styled.button`
	height: 40px;
	// padding: 0 4rem;
	color: #f15e38;
	background: transparent;
	outline-style: none;
	font-weight: 500;
	font-size: 16px;
	border: none;
	border-radius: 5px;
	cursor: pointer;
`;
