import styled from "styled-components";
import { BreakPoints } from "../../utils/breakpoints";
import { AppColors } from "../../utils/constants";

type DropdownPropT = {
	showDropdown: boolean;
};

export const Cont = styled.div`
	position: fixed;
`;
export const DBHeader = styled.div`
	width: 100%;
	padding: 1rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	/* border: 2px solid #fff; */
	position: sticky;
	top: -1.5rem;
	z-index: 20;
	border-radius: 10rem;
	/* background: #161b45; */

	@media (${BreakPoints.xs}) {
		border-radius: 0rem;
	}
	.header {
		@media (max-width: 600px) {
			display: block !important;
		}
	}
`;

export const DispayDesktop = styled.div`
	@media (${BreakPoints.xs}) {
		display: none;
	}

	@media (${BreakPoints.large}) {
		display: block;
		display: flex;
		align-items: center;
		justify-content: center;
	}
`;

export const DropDownContainer = styled.div<DropdownPropT>`
	position: absolute;
	top: 6rem;
	width: 15%;
	right: 8rem;
	min-height: 10rem;
	background: #fff;
	border-radius: 0.4rem;
	transition: all ease 0.8s;
	display: ${(prop) => (prop.showDropdown ? "block" : "none")};
	color: ${AppColors.brandBlack};
	padding: 0.4rem;

	ul {
		list-style: none;
	}

	li {
		border-bottom: 1px solid #cad3db;
		padding: 0.6rem;
		font-size: 0.9rem;
		cursor: pointer;
		transition: all ease 0.8s;
		:hover {
			padding-left: 0.2rem;
		}
	}
	li:last-child {
		border: none;
	}
	@media (${BreakPoints.xs}) {
		width: 45%;
		top: 5rem;
		right: 0rem;
	}

	@media (${BreakPoints.large}) {
		top: 5rem;
		right: 0rem;
	}
`;
