import React, { useEffect, useState } from "react";
import {
	Card,
	Button,
	BoldText,
	TutorContainer,
	Center,
	ShowMore,
	ButtonOutline,
} from "./Style";
import { getStoredClientUser } from "../../utils/LS";
import StudentOBJ from "../../classes/student.class";
import UserObj from "../../classes/user.class";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Spinner from "../../Components/Spinner";
import Rating from "../../Components/Rating";

type cardProp = {
	price: string;
	review?: any;
	desc: string;
	requestId: string;
	userId: string;
};
export default function Index({ payload }: any) {
	const [isLoading, setIsLoading] = useState(false);
	const [isLoading2, setIsLoading2] = useState(false);
	const navigate = useNavigate();
	const [showMOre, setShowMOre] = useState(false);
	const { userID } = getStoredClientUser();
	const [data, setData] = useState<cardProp | any>({});
	useEffect(() => {
		async function fetchData() {
			await StudentOBJ.getTutorapplication(payload).then((resp: any) => {
				if (resp.status) {
					setData(resp.payload);
				}
			});
		}
		fetchData();

		return () => {};
	}, [payload]);
	const applicationHandler = async () => {
		setIsLoading(true);
		let payload: any = {
			tutorId: data.userId._id,
			studentId: userID,
			requestId: data.requestId,
		};
		await StudentOBJ.accept_tutor_request(payload).then((res: any) => {
			if (res) {
				if (res?.status === true) {
					toast.success(res?.message);
					setIsLoading(false);
					navigate("/timeline");
				} else {
					toast.error(res?.message);
					setIsLoading(false);
				}
			} else {
				toast.error(res?.message);
				setIsLoading(false);
			}
		});
	};

	const messageTutor = async () => {
		setIsLoading2(true);
		let payload: any = {
			friendId: data.userId._id,
			userid: userID,
		};
		await UserObj.new_chat(payload).then((res: any) => {
			if (res) {
				if (res?.status === true) {
					setIsLoading2(false);
					navigate("/message/me");
				} else {
					toast.error(res?.message);
					setIsLoading2(false);
				}
			} else {
				toast.error(res?.message);
				setIsLoading2(false);
			}
		});
	};
	// Step 1: Use reduce to sum up the ratings
	const totalRating = data?.userId?.reviews?.reduce(
		(sum: any, item: any) => sum + item.rating,
		0
	);

	// Step 2: Calculate the average by dividing the total rating by the number of items
	const averageRating = totalRating / data?.userId?.reviews?.length;
	// console.log(averageRating);
	return JSON.stringify(data) !== "{}" ? (
		<Card>
			<Center>
				<img
					width={60}
					height={60}
					style={{ borderRadius: "50%" }}
					src={
						data?.requestId?.studentPhoto ||
						data?.avatar ||
						data?.tutorID?.photo
					}
					alt="...."
				/>
				<div>{data?.fullName || data.tutorID.fullname}</div>
				<div style={{ flexDirection: "row" }}>
					<Rating type="review" count={averageRating} />
				</div>
				{/* <div>{data?.userId?.subject}</div> */}
				{/* <div>{data?.userId?.country}</div> */}
				<div>NGN {data?.chargePerHour || data?.tutorID?.chargePerHour} /h</div>
			</Center>
			<div> {data?.userId?.bio || data?.tutorID?.bio} </div>
			{data?.tutorID?.classLocation && (
				<div>
					Preferred Class Location:{" "}
					{data?.tutorID?.classLocation === "google_classroom"
						? "Google Classroom"
						: data?.tutorID?.classLocation === "google_meet"
						? "Google meet"
						: data?.tutorID?.classLocation === "teams"
						? "Teams"
						: "Zoom"}
				</div>
			)}
			{data?.userId?.reviews?.length || data?.tutorID?.reviews.length ? (
				<React.Fragment>
					<h3
						style={{
							fontSize: "20px",
							fontWeight: 400,
						}}
					>
						<BoldText>Review and feedback</BoldText>
					</h3>
					{data?.userId?.reviews
						?.slice(Math.max(data?.userId?.reviews?.length - 5, 0))
						?.map((review: any, index: number) => {
							return (
								<TutorContainer key="index">
									<div className="title">
										<h1>
											{review.title ||
												(review?.ratingText ===
												"the class experience was not satisfactory"
													? "Unsatisfactory"
													: review?.ratingText)}
										</h1>
										<div>
											<Rating count={review.rating} type="review" />
										</div>
									</div>
									<p>
										{(review?.description?.length > 80 ||
											review?.feedback?.length > 80) &&
										!showMOre
											? review?.description?.length > 80
												? review?.description.slice(0, 200) + "...."
												: review?.feedback.slice(0, 200) + "...."
											: review?.description || review?.feedback}

										<span>
											{review?.description?.length > 80 && (
												<ShowMore onClick={() => setShowMOre(!showMOre)}>
													{showMOre ? "Show less" : "Show More"}
												</ShowMore>
											)}
										</span>
									</p>
									<p>
										<i>by {review.studentName || "anonymous"}</i>
									</p>
								</TutorContainer>
							);
						})}
				</React.Fragment>
			) : (
				<h3
					style={{
						fontSize: "16px",
						fontWeight: 400,
						fontStyle: "normal",
						fontFamily: "Roboto",
						margin: "20px 0",
					}}
				>
					No Review Yet
				</h3>
			)}
			{!data.tutorID && (
				<Button disabled={isLoading} onClick={applicationHandler}>
					{isLoading ? <Spinner isLoading={isLoading} /> : "Accept Application"}
				</Button>
			)}

			{!data.tutorID && (
				<ButtonOutline disabled={isLoading2} onClick={messageTutor}>
					{isLoading ? <Spinner isLoading={isLoading2} /> : "Message Tutor"}
				</ButtonOutline>
			)}
			{/* <Button>Book tutor</Button> */}
			{/* <ButtonOutline>Message tutor</ButtonOutline> */}
		</Card>
	) : (
		<div>Loading...</div>
	);
}
