import { Route, Routes } from "react-router-dom";
import Dashboard from "./Dashboard";
import TimeLine from "./TimeLine";
import MyKlass from "./klass";
import PastKlass from "./PastKlass";
import PostRequest from "./PostRequest";
import Wallet from "./Wallet";
import FundWallet from "./Wallet/FundWallet";
import Withdraw from "./Wallet/Withdraw";
import TutorProfile from "./TutorRequest";
import TutorApply from "./TutorApplyForm";
import Application from "./TutorApplication";
import Submissions from "./Submissions";
import ScheduleClass from "./ScheduleClass";
import Test from "./Test";
import Settings from "./settings";
import Review from "./Reviews/Reviews";
import Requestpage from "./Requestpage";
import Login from "../PublicPages/Login";
import LinkedClass from "./LinkedClass";
import Subscription from "./Subscription";
import SubscriptionTable from "./SubscriptionsTable";
import CreateCourse from "./course/Index";
import Message from "./Message";
import MessageMobile from "./Message/mobileView";
import Settlement from "./Settlement";

export default function AuthRoutes() {
	return (
		<Routes>
			<Route path="/" element={<Dashboard />} />
			<Route path="/post-request" element={<PostRequest />} />
			<Route path="/timeline" element={<TimeLine />} />
			<Route path="/class" element={<MyKlass />} />
			<Route path="/request/:id/tutor-applications" element={<Application />} />
			<Route path="/tutor-request" element={<TutorProfile />} />
			<Route path="/past-class" element={<PastKlass />} />
			<Route path="/submissions" element={<Submissions />} />
			<Route path="/test" element={<Test />} />
			<Route path="/schedule-class" element={<ScheduleClass />} />
			<Route path="/apply/:id" element={<TutorApply />} />
			<Route path="/wallet" element={<Wallet />} />
			<Route path="/settlement" element={<Settlement />} />
			{/* <Route path="/Class" element={<ClassLayout />}>
        <Route index element={<Class />} />
      </Route> */}
			<Route path="/Class/create-class" element={<CreateCourse />} />
			{/* <Route path="/Class/create-class" element={<CreateClass />} /> */}
			<Route path="/create-course" element={<CreateCourse />} />
			<Route path="/apply/:id" element={<TutorApply />} />
			<Route path="/withdraw" element={<Withdraw />} />
			<Route path="/fund-wallet" element={<FundWallet />} />
			<Route path="/settings" element={<Settings />} />
			<Route path="/class/session-end" element={<Review />} />
			<Route path="/request/:id" element={<Requestpage />} />
			<Route path="/subscription" element={<Subscription />} />
			<Route path="/subscription/all" element={<SubscriptionTable />} />
			<Route path="/message/me" element={<Message />} />
			<Route path="/message/me/2" element={<MessageMobile />} />
			<Route path="/klasshour/:id/class/:classId" element={<LinkedClass />} />
			<Route path="*" element={<Login />} />
		</Routes>
	);
}
