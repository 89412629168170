import React, { useState } from "react";
import DashboardHeader from "../../Layouts/DBHeader";
import { PageLayout } from "./Styles";
import SubjectDetails from "./SubjectDetails";
import CouseDate from "./CouseDate";
import CouseAmount from "./CouseAmount";
import Preview from "./Preview";
import LinkComp from "./LinkComp";

export default function CreateCourse() {
  const [currentStep, setcurrentStep] = useState(1);
  const [stepData, setstepData] = useState<any>([]);
  const [classLink, setclassLink] = useState("");

  return (
    <div>
      <PageLayout>
        <DashboardHeader backgroundColor="" />
        <div className="p-4 bg-white mt-10 rounded-md">
          {currentStep === 1 && (
            <SubjectDetails
              setcurrentStep={setcurrentStep}
              setstepData={setstepData}
              stepData={stepData}
            />
          )}

          {currentStep === 2 && (
            <CouseDate
              setcurrentStep={setcurrentStep}
              setstepData={setstepData}
              stepData={stepData}
            />
          )}

          {currentStep === 3 && (
            <CouseAmount
              setcurrentStep={setcurrentStep}
              setstepData={setstepData}
              stepData={stepData}
            />
          )}

          {currentStep === 4 && (
            <Preview
              setcurrentStep={setcurrentStep}
              setstepData={setstepData}
              stepData={stepData}
              setclassLink={setclassLink}
            />
          )}

          {currentStep === 5 && <LinkComp classLink={classLink} />}
        </div>
      </PageLayout>
    </div>
  );
}
