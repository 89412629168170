import React, { useState } from "react";

export default function LinkComp({ classLink }: any) {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopyClick = (classid: any, id: any) => {
    navigator.clipboard.writeText(`https://app.klasshour.com/${classLink}`);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1500);
  };
  return (
    <div>
      <div className=" py-2 text-gray-600 pb-40">
        <div className="border rounded-md p-4 py-20 mt-14 lg:w-6/12 m-auto bg-gray-50">
          <h2 className="font-bold text-center text-3xl text-green-400">
            Congratulations!!!
          </h2>
          <p className="text-center mt-4 text-sm w-10/12 m-auto">
            Class created successfully, you can copy and share the link with
            prospective subscribers
          </p>
          <span
            className="block mt-8 py-2 px-12 cursor-pointer bg-slate-800 text-white rounded-md text-center w-5/12 m-auto"
            onClick={() => handleCopyClick("7832372", "0000022")}
          >
            {isCopied ? "Copied" : "Copy"}
          </span>
        </div>
      </div>
    </div>
  );
}
