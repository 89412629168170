import UserLayout from "../../Layouts/UserLayout/UserLayout";
import { Container, MessageBody, MessageHeader, SecondSection } from "./Styles";
import { FaCircleArrowRight } from "react-icons/fa6";
import { useEffect, useRef, useState } from "react";
import { socket } from "../../API";
import userOBJ from "../../classes/user.class";
import { getFriend } from "../../utils/some";
import { getStoredClientUser } from "../../utils/LS";
import Spinner from "../../Components/Spinner";
import { toast } from "react-toastify";
import moment from "moment";
const Message = () => {
	const [data, setData] = useState<any>([]);
	const [screenWidth, setScreenWidth] = useState(window.innerWidth);
	const [selectedChat, setSelectedChat] = useState(data[0]);
	const [isLoading, setIsLoading] = useState(false);
	const [messages, setMessages] = useState<any>([]);
	const [newMsg, setNewMsg] = useState("");

	const lastMessageRef = useRef<HTMLDivElement>(null);
	const audioRef: any = useRef<HTMLAudioElement>(null);
	useEffect(() => {
		async function fetchData() {
			await userOBJ
				.get_all_chats(getStoredClientUser()?.userID)
				.then((resp: any) => {
					if (resp.status) {
						setData(resp.populatedResults);
						setSelectedChat(resp.populatedResults[0]);
					}
				});
		}
		fetchData();

		return () => {};
	}, [isLoading]);
	useEffect(() => {
		const handleResize = () => {
			setScreenWidth(window.innerWidth);
		};

		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);
	function playAudio() {
		audioRef.current.play();
	}
	useEffect(() => {
		// Listen for incoming messages
		socket.on("message", (message) => {
			setMessages((prevMessages: any) => [...prevMessages, message]);
			if (lastMessageRef.current) {
				lastMessageRef.current.scrollIntoView({ behavior: "smooth" });
			}

			// Play sound when new message arrives
			if (messages.length > 0) {
				if (audioRef.current) {
					audioRef.current.play();
				}
			}
			socket.emit(
				"messageRead",
				selectedChat?._id,
				getStoredClientUser()?.userID
			);

			playAudio();
		});

		// Clean up event listeners
		return () => {
			socket.off("message");
		};
	}, []);
	useEffect(() => {
		async function fetchData() {
			await userOBJ.get_all_messages(selectedChat?._id).then((resp: any) => {
				if (resp.status) {
					setMessages(resp.messages);
				}
			});
		}
		if (selectedChat) {
			fetchData();
		}

		return () => {};
	}, [selectedChat]);
	useEffect(() => {
		// Scroll to the bottom of the chat body when messages state updates or on initial load
		if (lastMessageRef.current) {
			lastMessageRef?.current.scrollIntoView({ behavior: "smooth" });
		}
	}, [messages, selectedChat]);
	const handleMessageSubmit = async (e: any) => {
		e.preventDefault();
		let payload = {
			chat: selectedChat._id,
			content: newMsg,
			type: "text",
			sender: getStoredClientUser()?.userID,
		};
		setIsLoading(true);
		await userOBJ.send_message(payload).then((res: any) => {
			setNewMsg("");

			if (res) {
				if (res?.status === true) {
					setIsLoading(false);
					socket.emit("sendMessage", payload);
					setMessages([...messages, payload]);
				} else {
					toast.error(res?.message);
					setIsLoading(false);
				}
			} else {
				toast.error(res?.message);
				setIsLoading(false);
			}
		});
	};
	return (
		<UserLayout>
			<Container>
				<SecondSection>
					<MessageHeader>
						<img
							src={
								getFriend(selectedChat?.users, getStoredClientUser()?.userID)
									?.photo
							}
							alt=""
						/>
						<h1>
							{
								getFriend(selectedChat?.users, getStoredClientUser()?.userID)
									?.fullname
							}
						</h1>
					</MessageHeader>
					<MessageBody>
						<form onSubmit={handleMessageSubmit}>
							<input
								type="text"
								placeholder="Type your message here"
								value={newMsg}
								onChange={(e: any) => setNewMsg(e.target.value)}
							/>
							{isLoading ? (
								<Spinner isLoading={isLoading} />
							) : (
								<FaCircleArrowRight
									color="rgb(241, 94, 56)"
									size={40}
									onClick={handleMessageSubmit}
								/>
							)}
						</form>
						<div className="content">
							<audio
								ref={audioRef}
								src="path/to/sound.mp3"
								className="hidden"
							/>
							{messages?.map((msg: any, i: any) => {
								return (
									<aside>
										{getStoredClientUser()?.userID !== msg?.sender?._id ? (
											<div style={{ display: "flex", gap: 10 }} key={i}>
												<img src={msg?.sender?.photo} alt="" />
												<div style={{ marginTop: -10 }}>
													<div className={`other`}>{msg.content}</div>
													<p style={{ marginTop: 8 }}>
														{moment(msg.createdAt).format("dddd [at] h:mm A")}
													</p>
												</div>
											</div>
										) : (
											<div
												style={{
													marginTop: -10,
													width: "100%",
													marginLeft: "auto",
												}}
											>
												<div className={`self`}>{msg.content}</div>
												<p style={{ marginTop: 8, marginLeft: "auto" }}>
													{moment(msg.createdAt).format("dddd [at] h:mm A")}
												</p>
											</div>
										)}
									</aside>
								);
							})}
							{messages?.length === 0 && (
								<div
									style={{
										textAlign: "center",
										display: "flex",
										justifyContent: "center",
										width: "100%",
									}}
								>
									<p>No messages yet</p>
								</div>
							)}
							<div ref={lastMessageRef} />
						</div>
					</MessageBody>
				</SecondSection>
			</Container>
		</UserLayout>
	);
};

export default Message;
