import { useEffect, useRef, useState } from "react";
import { BiChevronDown } from "react-icons/bi";

type RadioOption = {
  label: string;
  value: string;
};

// type RadioSelectProps = {
//   name: string;
//   options: RadioOption[];
//   formik: any;
// };
type DropDownSelectProps = {
  className: string;
  label?: string;
  containerStyle?: string;
  options: RadioOption[];
  onSelect?: (e: string) => void;
  placeholder: string;
  error?: any;
};

function Dropdown({
  options,
  onSelect,
  placeholder,
  className,
  label,
}: DropDownSelectProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<RadioOption>();

  function handleOptionClick(option: RadioOption) {
    setSelectedOption(option);
    setIsOpen(false);
    if (onSelect) onSelect(option.value);
  }

  const selectRef = useRef<any>(null);
  useEffect(() => {
    const handleOutsideClick = (event: any) => {
      if (selectRef.current && !selectRef.current?.contains(event.target)) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isOpen]);

  return (
    <div
      className="w-full relative inline-block text-left mb-2"
      ref={selectRef}
    >
      <label htmlFor={label} className=" mb-2">
        {label}
      </label>
      <div className="w-full mt-2">
        <button
          type="button"
          className={`${className} border border-slate-300 p-[0.84rem] inline-flex justify-between rounded-sm shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-primary `}
          id="options-menu"
          aria-expanded="true"
          aria-haspopup="true"
          onClick={() => setIsOpen(!isOpen)}
        >
          {selectedOption ? selectedOption.label : placeholder}

          <BiChevronDown size={24} />
        </button>
      </div>
      {isOpen && (
        <div className="origin-top-right absolute left-0 mt-2 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10 overflow-auto">
          <div
            className="py-1 overflow-auto max-h-60"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="options-menu"
          >
            {options.map((option) => (
              <button
                key={option.value}
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 w-full text-left"
                onClick={() => handleOptionClick(option)}
                role="menuitem"
              >
                {option.label}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default Dropdown;
