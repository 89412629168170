import React, { forwardRef, useState, useEffect } from "react";
import DatePicker, { CalendarContainer } from "react-datepicker";
import UserLayout from "../../Layouts/UserLayout/UserLayout";
import {
	RequestForm,
	RequestFormPageLayout,
	FormContainer,
	Input,
	FormInnerContainer,
	TextArea,
	FormError,
	PickerCont,
	Select,
} from "./Styles";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "../../Components/ApplicationCard/Style";
import { useForm } from "react-hook-form";
import Spinner from "../../Components/Spinner";
import StudentOBJ from "../../classes/student.class";
import { subjects } from "../../Shared/ClassData";
import { toast } from "react-toastify";
import { getStoredClientUser } from "../../utils/LS";
import { useNavigate } from "react-router-dom";
import { AppColors } from "../../utils/constants";
import axios from "axios";

type InputsPropT = {
	subject: string;
	desc: string;
	document: string;
	duration: number;
	emptySet: boolean;
};

export default function PostRequest() {
	const [isLoading, setIsLoading] = useState(false);
	const [startDate, setStartDate] = useState<any>("");
	const [subject, setSubject] = useState<any>("");
	const [duration, setDuration] = useState<number | string>(1);
	const [document, setDocument] = useState<string | any>("");
	const [docLoading, setDocLoading] = useState(false);
	const navigate = useNavigate();

	const goto = (path: string, data?: any) => {
		if (data) {
			navigate(path, data);
		} else {
			navigate(path);
		}
	};

	const MyContainer = ({ className, children }: any) => {
		return (
			<div
				style={{
					padding: "2px",
					background: AppColors.brandRed,
					color: "#fff",
				}}
			>
				<CalendarContainer className={className}>
					<div style={{ background: "#f0f0f0", padding: "0.2rem" }}>
						What is your favorite day?
					</div>
					<div style={{ position: "relative" }}>{children}</div>
				</CalendarContainer>
			</div>
		);
	};

	const ExampleCustomInput = forwardRef(({ value, onClick }: any, ref: any) => (
		<PickerCont
			onChange={onClick}
			ref={ref}
			value={value}
			onClick={onClick}
			placeholder="Select date and time for this class"
			required
		/>
	));

	const handleImageChange = async (event: any) => {
		if (event.target.files[0].type !== "application/pdf") {
			toast.error("Only pdf file allowed");
			setDocument(null);
			return;
		}
		console.log(event.target.files[0]);
		if (event.target.files[0].size >= 10485760) {
			toast.error("File too large");
			setDocument(null);
			return;
		}
		setDocLoading(true);
		const formData = new FormData();
		formData.append("file", event.target.files[0]);
		formData.append("upload_preset", "rreoys4n");
		await axios
			.post("https://api.cloudinary.com/v1_1/classhour/image/upload", formData)
			.then((res: any) => {
				setDocument(res.data["secure_url"]);
				setDocLoading(false);
			});
	};
	const { email, merithubUserID, userID } = getStoredClientUser();

	let schema = Yup.object({
		subject: Yup.string().required("Required!"),
		desc: Yup.string()
			.required("Required!")
			.min(10, "description is too short - should be 10 chars minimum."),
	});

	const {
		register,
		handleSubmit,

		formState: { errors },
	} = useForm<InputsPropT>({
		resolver: yupResolver(schema),
	});

	const handlePostRequst = async (values: any) => {
		setIsLoading(true);
		const RqData = {
			subject: subject ?? values.subject,
			schedule: startDate,
			description: values.desc,
			studentEmail: email,
			studentID: userID,
			duration: duration,
			merithubStudentID: merithubUserID,
			file: document,
		};
		if (startDate) {
			StudentOBJ.post_request(RqData).then((res: any) => {
				if (res) {
					if (res?.status === true) {
						toast.success(res?.message);
						setIsLoading(false);
						goto("/timeline");
					} else if (res?.status !== true && res.reason === "No photo") {
						goto("/settings");
					} else {
						toast.error(res?.message);
						setIsLoading(false);
					}
				} else {
					toast.error(res?.message);
					setIsLoading(false);
				}
				setIsLoading(false);
			});
		} else {
			toast.info("You need to pick a schedule date and time");
			setIsLoading(false);
		}
	};
	const [matchingSubjects, setMatchingSubjects] = useState<string[]>([]);
	const validateForm = () => {};

	useEffect(() => {
		const delaySearch = setTimeout(() => {
			const filteredSubjects = subjects.filter((sub) =>
				sub.toLowerCase().includes(subject.toLowerCase())
			);
			setMatchingSubjects(filteredSubjects);
		}, 300); // Debounce time in milliseconds

		return () => clearTimeout(delaySearch);
	}, [subject]);
	useEffect(() => {}, [startDate]);

	return (
		<UserLayout>
			<RequestFormPageLayout>
				<h2>Get Help</h2>
				<RequestForm onSubmit={handleSubmit(handlePostRequst)}>
					<FormInnerContainer>
						<FormContainer>
							<label>Subject</label>
							{errors.subject && (
								<FormError>{errors.subject.message}</FormError>
							)}

							<Input
								{...register("subject", { required: true })}
								placeholder="physics"
								type="text"
								value={subject}
								onChange={(e: any) => setSubject(e.target.value)}
							/>
						</FormContainer>
						{subject.length > 2 && matchingSubjects.length > 0 && (
							<ul className="flex gap-2 mb-2 w-full flex-wrap">
								{matchingSubjects.map((subject, index) => (
									<li
										className="rounded-full font-bold p-2"
										key={index}
										onClick={() => setSubject(matchingSubjects[index])}
									>
										{subject}
									</li>
								))}
							</ul>
						)}

						<FormContainer>
							<label>Schedule</label>
							<DatePicker
								selected={startDate === "" ? null : startDate}
								onChange={(date: any) => setStartDate(date)}
								showTimeSelect
								timeFormat="HH:mm"
								required
								timeIntervals={25}
								timeCaption="time"
								minDate={new Date()}
								placeholderText={"Please select a date"}
								// dateFormat="MMMM d, yyyy h:mm aa"
								dateFormat="MMMM d yyy, h:mm:aa"
								customInput={<ExampleCustomInput />}
								calendarContainer={MyContainer}
							/>
						</FormContainer>

						<FormContainer>
							<label>Description</label>
							{errors.desc && <FormError>{errors.desc.message}</FormError>}
							<TextArea
								{...register("desc", { required: true })}
								placeholder="What academic challenge do you need help with?"
							/>
						</FormContainer>
						<FormContainer>
							<label>Duration</label>
							{errors.duration && (
								<FormError>{errors.duration.message}</FormError>
							)}
							<Select
								{...register("duration", { required: true })}
								onChange={(e) => {
									setDuration(e.target.value);
								}}
							>
								<option value={1}>1 hr</option>
								<option value={2}>2 hr</option>
								<option value={3}>3 hr</option>
								<option value={4}>4 hr</option>
							</Select>
						</FormContainer>
						<FormContainer>
							<label>
								Upload(optional){" "}
								<span
									style={{
										color: "red",
										fontStyle: "italic",
									}}
								>
									only pdf file allowed
								</span>
							</label>
							<input
								type="text"
								hidden
								{...register("emptySet", { required: true })}
							/>

							{errors.document && (
								<FormError>{errors.document.message}</FormError>
							)}
							<Input
								{...register("document", { required: true })}
								type="file"
								name="document"
								onChange={(e: any) => handleImageChange(e)}
							/>
						</FormContainer>
						<Button disabled={docLoading}>
							{docLoading ? (
								"Uploading file..."
							) : isLoading ? (
								<Spinner isLoading={isLoading} />
							) : (
								"Post Request"
							)}
						</Button>
					</FormInnerContainer>
				</RequestForm>
			</RequestFormPageLayout>
		</UserLayout>
	);
}
