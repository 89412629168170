import React, { useEffect, useState } from "react";
import Logo from "../../Assets/images/Logo.svg";
import Logo2 from "../../Assets/images/logo.png";
import { DBHeader, DispayDesktop, DropDownContainer } from "./Styles";
import Avatar from "../../Components/Avatar";
import ArrowDown from "../../Assets/icons/ArrowDown.svg";
import { Logout } from "../../utils/some";
import { Link, useNavigate } from "react-router-dom";
import { getStoredClientUser } from "../../utils/LS";
import { TbMessageDots } from "react-icons/tb";
import { socket } from "../../API";

export default function DashboardHeader(props: any) {
	const { avatar } = getStoredClientUser();
	const { userID, userType } = getStoredClientUser();
	const [showDropdown, setshowDropdown] = useState(false);
	const [hasMsg, setHasMsg] = useState(false);
	const UserAvater = () => {
		return (
			<div>
				<div
					style={{
						display: "flex",
						gap: "0.5rem",
						padding: "0 0.2rem",
						cursor: "pointer",
					}}
					onClick={() => setshowDropdown(!showDropdown)}
				>
					<Avatar size="2.4rem" photo={avatar} />
					<DispayDesktop>
						<img src={ArrowDown} style={{ width: "1rem" }} alt="" />
					</DispayDesktop>
				</div>
				<DropDownContainer
					// onMouseLeave={() => setshowDropdown(!showDropdown)}
					showDropdown={showDropdown}
				>
					<ul>
						{/* <li><a href="/settings">Profile Settings</a></li> */}
						<li>
							<a href="/wallet">Wallet</a>
						</li>
						<li>
							<a href="/settings">My Profile</a>
						</li>
						<li onClick={() => Logout()}>Logout</li>
					</ul>
				</DropDownContainer>
			</div>
		);
	};

	useEffect(() => {
		console.log(getStoredClientUser());
	}, []);
	const nav = useNavigate();
	useEffect(() => {
		socket.emit("messageUnRead", getStoredClientUser()?.userID);
		socket.on("message_unread", ({ hasUnreadMsg }) => {
			setHasMsg(hasUnreadMsg);
		});
	}, []);
	return (
		<>
			<DBHeader
				style={{
					background:
						props.backgroundColor === "light"
							? "#fff"
							: `linear-gradient(
    90deg,
    rgba(22, 27, 69, 1) 0%,
    rgba(37, 34, 89, 1) 37%,
    rgba(57, 49, 106, 1) 68%,
    rgba(73, 54, 125, 1) 100%
  )`,
				}}
			>
				<div className="flex items-center gap-4 header">
					<div className="mt-1">
						<Link to="/">
							{props.backgroundColor === "light" ? (
								<img src={Logo2} alt="logo" />
							) : (
								<img src={Logo} alt="logo" />
							)}
						</Link>
						{/* <span className="text-white mt-1 block">{userType}</span> */}
					</div>

					<span className="text-[#161B45] bg-green-400 px-4 rounded-sm text-sm font-bold">
						{userType}
					</span>
				</div>
				<div style={{ display: "flex", alignItems: "center", gap: 18 }}>
					<div
						style={{ position: "relative", cursor: "pointer" }}
						onClick={() => nav("/message/me")}
					>
						<TbMessageDots color="white" size={24} />
						{hasMsg && (
							<div
								style={{
									position: "absolute",
									width: 5,
									height: 5,
									background: "red",
									top: 3,
									right: 0,
									borderRadius: "50%",
								}}
							></div>
						)}
					</div>
					<div>{userID && UserAvater()}</div>
				</div>
			</DBHeader>
		</>
	);
}
