import {
  Background,
  InviteCard,
  Wrapper,
  Container,
  ButtonContainer,
} from "./styles";
import { CardButton } from "../RequestCardPage/Styles";
import asterisks from "../../Assets/icons/accept-invite-pop-up-union.png";
import moment from "moment";
import { useNavigate, Link } from "react-router-dom";
import { getStoredClientUser } from "../../utils/LS";
import studentRequest from "../../classes/request.class";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import React from "react";

type RequestPropT = {
  data: {
    date: any;
    isOpen: boolean;
    isClass: boolean;
    subject: string;
    language: any;
    description: any;
    duration: number;
    applicants: any;
    createdAt: string;
    schedule: string;
    file?: string;
    merithubTutorID: string;
    _id: string;
  };
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AcceptInvite({ data }: RequestPropT) {
  const { userType, userID } = getStoredClientUser();
  const navigate = useNavigate();
  const getTutorApplication = (requestID: string) => {
    if (userID === undefined) {
      navigate("/login");
    }

    if (userType === "Student") {
      studentRequest.get_all_tutor_request(requestID).then((response) => {
        navigate(`/request/${requestID}/tutor-applications`, {
          state: { response, requestID },
        });
      });
    } else {
      navigate(`/apply/${requestID}`, { state: requestID });
    }
  };
  const scheduleTime = data?.schedule;

  const [open, setOpen] = React.useState(true);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    // <Background>
    //   <InviteCard>
    //     <h3>Submit Application</h3>
    //     <Wrapper>
    //       <div>
    //         <p>
    //           This class will take place on{" "}
    //           <b>{moment(scheduleTime).format("LLLL")} WAT.</b>{" "}
    //         </p>
    //         <p>Would you be available to take this session?</p>
    //       </div>

    //       <Container>
    //         <img src={asterisks} alt="" />
    //         <p>
    //           If you miss three sessions, your account will be suspended
    //           temporarily.
    //         </p>
    //       </Container>

    //       <ButtonContainer>
    //         <Button
    //           onClick={() => getTutorApplication(data?._id)}
    //           disabled={
    //             userType !== "Student" || !userID
    //               ? data?.applicants?.find((el: any) => el.userId === userID) ||
    //                 !data.isOpen
    //               : !data?.applicants?.length || !data.isOpen
    //           }
    //         >
    //           Yes
    //         </Button>

    //         <Link to="/">No</Link>
    //       </ButtonContainer>
    //     </Wrapper>
    //   </InviteCard>
    // </Background>
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Hello Tutor 👋🏽"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            This class will take place on {moment(scheduleTime).format("LLLL")}{" "}
            WAT.
            <p>
              If you miss three sessions, your account may be temporarily
              suspended.
            </p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Disagree</Button>
          <Button onClick={() => getTutorApplication(data?._id)}>Agree</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
