import React, { useEffect, useState } from "react";
import {
	Flex,
	Card,
	ButtonContainer,
	FlexHeader,
	Button,
	LightText,
	BoldText,
} from "./Style";
import Avatar from "../../Assets/images/Image.jpeg";
import { useNavigate } from "react-router-dom";
import Spinner from "../Spinner";

export default function Index({ data }: any) {
	const [screenWidth, setScreenWidth] = useState(window.innerWidth);
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(false);
	useEffect(() => {
		const handleResize: any = () => {
			setScreenWidth(window.innerWidth);
		};

		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);
	const handleOnclickEvent = (request: any) => {
		setIsLoading(true);
		setTimeout(() => {
			if (screenWidth <= 600) {
				navigate("/message/me/2", { state: request });
			} else {
				navigate("/message/me", { state: request });
			}
		}, 3000);
	};
	return (
		<Card>
			<FlexHeader>
				<img
					src={data?.requestId?.studentPhoto || Avatar}
					alt="..."
					width={40}
					height={40}
					style={{ borderRadius: "50%" }}
					className="w-16 h-16"
				/>
				<div>NGN{data.chargePerHour} P/H</div>
			</FlexHeader>
			<h3
				style={{
					fontSize: "20px",
					fontWeight: 400,
				}}
			></h3>
			{/* <LightText>{data.bio}</LightText> */}
			<Flex>
				<BoldText>Student: </BoldText>
				<LightText> {data.requestId?.studentName}</LightText>
			</Flex>
			<Flex>
				<BoldText>subject: </BoldText>
				<LightText>{data?.requestId?.subject}</LightText>
			</Flex>
			<Flex>
				<BoldText>Description:</BoldText>
				<LightText>{data.requestId?.description}</LightText>
			</Flex>
			<ButtonContainer>
				<Button
					disabled={!data.isAccepted || isLoading || data?.requestId?.isClass}
					onClick={() => {
						handleOnclickEvent(data);
					}}
				>
					{isLoading ? (
						<Spinner isLoading={isLoading} />
					) : data?.requestId?.isClass ? (
						"Class Scheduled"
					) : (
						"Schedule Class"
					)}
				</Button>
			</ButtonContainer>
		</Card>
	);
}
