import React from "react";
import { CardWrapper, TableContainer, TableWrapper, Wrapper } from "./Styles";
import UserLayout from "../../Layouts/UserLayout/UserLayout";
import Arrow from "../../Assets/images/backLogo.png";
import EmptyData from "../../Components/EmptyData";
import { useLocation, useNavigate } from "react-router-dom";

const SubscriptionTable = (props: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  return (
    <UserLayout>
      <Wrapper>
        <img
          src={Arrow}
          alt=""
          width={47}
          height={40}
          onClick={() => navigate(-1)}
        />
        <CardWrapper>
          <h1>All Subscribers</h1>
          <TableWrapper>
            <TableContainer>
              <thead>
                <tr>
                  <th>S/N</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th></th>
                </tr>
              </thead>
              {state.length > 0 && (
                <tbody>
                  {state.map((el: any, i: number) => {
                    return (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>{el.fullname}</td>
                        <td>{el.email}</td>
                      </tr>
                    );
                  })}
                </tbody>
              )}
            </TableContainer>
            {state.length === 0 && <EmptyData data="Subscriber" />}
          </TableWrapper>
        </CardWrapper>
      </Wrapper>
    </UserLayout>
  );
};

SubscriptionTable.defaultProps = {
  data: [
    {
      serial_no: 1,
      name: "Aluko Opeyemi",
      email: "123@gmail.com",
    },
    {
      serial_no: 2,
      name: "Olabode Eto",
      email: "abc@gmail.com",
    },
  ],
};
export default SubscriptionTable;
