import React, { useState } from "react";
import { PrimaryBtn } from "../../Components/Button";
import { getStoredClientUser } from "../../utils/LS";
import userOBJ from "../../classes/user.class";
import { toast } from "react-toastify";

export default function Preview({
  setcurrentStep,
  stepData,
  setstepData,
  setclassLink,
}: any) {
  const [loading, setLoading] = useState(false);
  const durationList = [
    { value: "60", label: "1 hour" },
    { value: "90", label: "1hour 30mins" },
    { value: "120", label: "2 hours" },
    { value: "150", label: "2 hours 30 mins" },
    { value: "180", label: "3 hours" },
    { value: "210", label: "3 hours 30 mins" },
    { value: "240", label: "4 hours" },
  ];
  const durr = stepData[4];
  const gethours = durationList.filter((obj) => obj.value === durr);

  const handleCreate = async () => {
    if (loading) return;
    setLoading(true);
    let payload = {
      subject: stepData[0],
      level: stepData[1],
      description: stepData[2],
      thumbnail: stepData[3],
      ChargeableClass: false,
      startTime: stepData[4],
      endTime: stepData[4],
      duration: stepData[5],
      amount: stepData[6],
      dateValue: stepData[4],
      tutorID: getStoredClientUser().userID,
    };
    await userOBJ.create_class(payload).then((res: any) => {
      if (res.status) {
        setclassLink(res.link);
        localStorage.removeItem("ClassData");
        setLoading(false);
        setcurrentStep(5);
        toast.success(res.message);
      } else {
        toast.error(res.message);
        setcurrentStep(3);
        setLoading(false);
        return null;
      }
    });
  };
  return (
    <div>
      <div className=" py-2 text-gray-600 pb-40">
        <div className="border-l-4 border-orange-500 pl-2">
          <h2 className="text-2xl">Preview</h2>
          <p className="font-light">
            You are almost done, verify your entries before you create the
            class.
          </p>
        </div>

        <div className="border border-red-400 rounded-md py-12 mt-14 lg:w-6/12 m-auto px-10 ">
          <p className="flex gap-4 border border-slate-200 mb-5 p-3 items-center">
            <label className="font-bold text-sm">Subject:</label>
            <label className="text-sm">{stepData[0]}</label>
          </p>

          <p className="flex gap-4 border border-slate-200 mb-5 p-3 items-center">
            <label className="font-bold text-sm">Level</label>
            <label className="text-sm">{stepData[1]}</label>
          </p>

          <div className="flex flex-col items-start gap-4 border border-slate-200 mb-5 p-3">
            <p>
              <label className="font-bold text-sm">Description:</label>
            </p>
            <p>{stepData[2]}</p>
          </div>

          {stepData[3] && (
            <div className="flex flex-col items-start gap-4 border border-slate-200 mb-5 p-3">
              <p>
                <label className="font-bold text-sm">Thumbnail:</label>
              </p>
              <p className="break-words">{stepData[3]}</p>
            </div>
          )}

          <p className="flex gap-4 border border-slate-200 mb-5 p-3 items-center">
            <label className="font-bold text-sm">Date</label>
            <label className="text-sm">
              {stepData[4].toLocaleDateString() + " "}
              {stepData[4].toLocaleString("en-US", {
                timeZone: "Africa/Lagos",
                hour: "numeric",
                minute: "numeric",
              })}
            </label>
          </p>

          <p className="flex gap-4 border border-slate-200 mb-5 p-3 items-center">
            <label className="font-bold text-sm">Amount per hour</label>
            <label className="text-sm">NGN{stepData[5]}</label>
          </p>

          <p className="flex gap-4 border border-slate-200 mb-5 p-3 items-center">
            <label className="font-bold text-sm">Duration</label>
            <label className="text-sm">{gethours[0]?.label ?? "---"}</label>
          </p>

          <div className="mt-10 flex items-center gap-4">
            <PrimaryBtn
              btnType="button"
              title="Go Back"
              onBtnClick={() => {
                const subject = stepData[0];
                const level = stepData[1];
                const desc = stepData[2];
                setstepData([]);
                setstepData([subject, level, desc]);
                setcurrentStep(1);
              }}
            />
            <PrimaryBtn
              isLoading={loading}
              btnType="button"
              title="Create Class"
              onBtnClick={() => handleCreate()}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
