import styled from "styled-components";

export const Star = styled.span`
  cursor: pointer;
  color: #ccc;
  margin-right: 7px;

  &.checked {
    color: orange;
  }
`;
