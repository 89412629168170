import React, { useEffect, useState } from "react";
import UserLayout from "../../Layouts/UserLayout/UserLayout";
import { useNavigate, useParams } from "react-router-dom";
import StudentOBJ from "../../classes/student.class";
import { toast } from "react-toastify";
import Avatar from "../../Assets/icons/Image.png";
import Rating from "../../Components/Rating";
import {
	ButtonLeft,
	ButtonRight,
	Card,
	LeftAligned,
	ShowMore,
	CardWrapper,
} from "./Styles";
import { truncateText } from "../../utils/some";
import { getStoredClientUser } from "../../utils/LS";
import Spinner from "../../Components/Spinner";

import Pagination from "../../Components/Pagination";
import { TbMessageDots } from "react-icons/tb";

export default function Index() {
	const { id } = useParams();
	const [isLoading, setIsLoading] = useState<string | boolean>(false);
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [totalPages, setTotalPages] = useState<number>(1);
	const [showMOre, setShowMOre] = useState(false);
	const { userID } = getStoredClientUser();
	const navigate = useNavigate();
	const [tutorData, setTutorData] = useState<any>([]);

	useEffect(() => {
		StudentOBJ.get_tutors_applications(id, currentPage).then((res: any) => {
			setTutorData(res.payload);
			setTotalPages(res.totalPages);
		});
	}, []);
	const applicationHandler = async (tutorId: string) => {
		setIsLoading(tutorId);
		let payload: any = {
			tutorId,
			studentId: userID,
			requestId: id,
		};
		await StudentOBJ.accept_tutor_request(payload).then((res: any) => {
			if (res) {
				if (res?.status === true) {
					toast.success(res?.message);
					setIsLoading(false);
					navigate("/timeline");
				} else {
					toast.error(res?.message);
					setIsLoading(false);
				}
			} else {
				toast.error(res?.message);
				setIsLoading(false);
			}
		});
	};
	const getTutorProfile = async (data: any) => {
		navigate("/tutor-request", { state: data });
	};
	console.log(tutorData[0]?.reviews);
	// Step 1: Use reduce to sum up the ratings
	const totalRating = tutorData[0]?.reviews?.reduce(
		(sum: any, item: any) => sum + item.rating,
		0
	);

	// Step 2: Calculate the average by dividing the total rating by the number of items
	const averageRating = totalRating / tutorData[0]?.reviews?.length;
	// console.log(totalRating);
	return (
		<UserLayout>
			<CardWrapper>
				{tutorData?.length > 0
					? tutorData?.map((el: any, i: number) => {
							return (
								<React.Fragment key={i}>
									<Card>
										<div className="header">
											{/* <p style={{ textAlign: 'center' }}> About { el.fullName.split(" ")[0]}</p> */}
											<img
												width={60}
												height={60}
												style={{ borderRadius: "50%" }}
												src={el?.avatar || Avatar}
												alt="..."
											/>
											<div className="top">
												<Rating type="review" count={averageRating} />
												<p>NGN {el.chargePerHour}/h</p>
											</div>
										</div>
										<h1>{el.fullName}</h1>

										<div>
											{" "}
											{!showMOre && el?.bio?.length > 80
												? truncateText(el.bio, 80)
												: el.bio}
										</div>
										{el?.bio?.length > 80 && (
											<ShowMore onClick={() => setShowMOre(!showMOre)}>
												{showMOre ? "Show less" : "Show More"}
											</ShowMore>
										)}
										<div>
											{" "}
											Subject: <b>{el.subject}</b>{" "}
										</div>
										<div>
											Language : <b>{el.language}</b>{" "}
										</div>
										<LeftAligned>
											<div
												style={{
													display: "flex",
													alignItems: "center",
													gap: 18,
												}}
											>
												<div
													style={{ position: "relative", cursor: "pointer" }}
													onClick={() => navigate("/message/me")}
												>
													<TbMessageDots color="white" size={24} />
												</div>
											</div>
											<ButtonLeft
												onClick={() =>
													getTutorProfile({
														name: el.fullName,
														requestId: el.requestId,
													})
												}
											>
												View Profile
											</ButtonLeft>
											<ButtonRight
												disabled={isLoading === el.userId}
												onClick={() => applicationHandler(el?.userId)}
											>
												{" "}
												{isLoading === el?.userId ? (
													<Spinner isLoading={isLoading === el?.userId} />
												) : (
													"Accept Application"
												)}
											</ButtonRight>
										</LeftAligned>
									</Card>
								</React.Fragment>
							);
					  })
					: "Loading..."}
				{tutorData?.length > 0 && (
					<Pagination
						totalPages={totalPages}
						currentPage={currentPage}
						callBack={(value: any) => {
							setCurrentPage(value);
							// getStudentRequests(value);
						}}
					/>
				)}
			</CardWrapper>
		</UserLayout>
	);
}
