import React, { useState } from "react";
import { ButtonLeft, ButtonRight, Card, LeftAligned } from "./Styles";
import { convertMinutesToHoursMinutes } from "../LinkedClass";
import { getStoredClientUser } from "../../utils/LS";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Spinner from "../../Components/Spinner";
import { ShowMore } from "../TutorApplication/Styles";
import { truncateText } from "../../utils/some";
import { toast } from "react-toastify";
import userOBJ from "../../classes/user.class";
import StudentOBJ from "../../classes/student.class";
import { CopyOutlined } from "@ant-design/icons";

type PropT = {
  data: any;
};

export default function SubCard({ data }: PropT) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isCopied, setIsCopied] = useState(false);
  const navigate = useNavigate();
  const { userType } = getStoredClientUser();
  const [showMOre, setShowMOre] = useState(false);

  function copyToClipboard(text: string) {
    // Create a temporary input element
    const input = document.createElement("input");
    input.value = text;
    document.body.appendChild(input);

    // Select the text inside the input element
    input.select();
    input.setSelectionRange(0, 99999); // For mobile devices

    // Copy the selected text to the clipboard
    document.execCommand("copy");

    // Remove the temporary input element
    document.body.removeChild(input);
  }
  const handleCopyLink = async (link: string) => {
    copyToClipboard("https://app.klasshour.com/" + link);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1500);
  };

  const joinClassHandler = async (data: any) => {
    setIsLoading(true);
    let payload = {
      merithubStudentID: getStoredClientUser().merithubUserID,
      classFee: data.amount,
      link: data.details.commonLinks.commonParticipantLink,
      isSubscribed: true,
    };
    await StudentOBJ.student_join_class(payload).then((res: any) => {
      if (res) {
        if (res?.status === true) {
          // toast.success(res?.message);
          window.location.replace(`${res.classLink}?devicetest=true`);
          setIsLoading(false);
        } else {
          toast.error(res?.message);
          setIsLoading(false);
        }
      } else {
        toast.error(res?.message);
        setIsLoading(false);
      }
    });
  };
  const startClassHandler = async (classDetail: any) => {
    setIsLoading(true);
    let payload = {
      merithubUserID: getStoredClientUser().merithubUserID,
      duration: classDetail.duration,
      subject: classDetail.subject,
      description: classDetail.description,
      classId: classDetail._id,
      startTime: classDetail.startTime,
      // startTime: `${new Date(classDetail.dateValue).toLocaleDateString()} ${
      //   classDetail.startTime
      // }`,
      amount: classDetail.amount,
      subscribers: classDetail.subscribers,
    };
    const response: any = await userOBJ.tutor_start_class(payload);
    if (response.status) {
      setIsLoading(false);
      window.open(`${response.preLink}?devicetest=true`);
    } else {
      setIsLoading(false);
      toast.error(response.message);
    }

    setIsLoading(false);
  };

  return (
    <Card>
      {isCopied && getStoredClientUser().userType !== "Student" ? (
        <small className="icon">Copied!</small>
      ) : (
        <CopyOutlined
          rev={"2"}
          className="icon"
          onClick={() => handleCopyLink(data?.link)}
        />
      )}

      <div className="flex">
        <p>Subject: </p>
        <p>
          <b>{data?.subject}</b>
        </p>
      </div>
      <div className="flex">
        <p>Tutor: </p>
        <p>
          <b>{data?.tutorID?.fullname}</b>
        </p>
      </div>
      <div className="flex">
        <p>Time and Date: </p>
        <p>
          <b>{new Date(data?.startTime).toLocaleString()}</b>
        </p>
      </div>
      <div className="flex">
        <p>Duration: </p>
        <p>
          <b>{convertMinutesToHoursMinutes(data?.duration)}</b>
        </p>
      </div>

      <p>
        {" "}
        {!showMOre && data?.description?.length > 130
          ? truncateText(data?.description, 130)
          : data?.description}
      </p>
      {data?.description?.length > 130 && (
        <ShowMore className="sm" onClick={() => setShowMOre(!showMOre)}>
          {showMOre ? "Show less" : "Show More"}
        </ShowMore>
      )}
      <LeftAligned
        className={getStoredClientUser().userType === "Student" ? "le" : "re"}
      >
        {getStoredClientUser().userType !== "Student" && (
          <>
            <ButtonLeft
              style={{ color: "#848484" }}
              onClick={() =>
                navigate("/subscription/all", { state: data?.subscribers })
              }
            >
              Subscribers
            </ButtonLeft>
            <ButtonLeft
              className="sms"
              onClick={() => handleCopyLink(data?.link)}
            >
              {isCopied ? "Copied!" : "Copy Link"}
            </ButtonLeft>
          </>
        )}
        <ButtonRight
          disabled={isLoading || !data.subscribers.length}
          onClick={() =>
            userType === "Student"
              ? joinClassHandler(data)
              : startClassHandler(data)
          }
        >
          {isLoading ? (
            <Spinner isLoading={isLoading} />
          ) : userType === "Student" ? (
            "Join Class"
          ) : (
            "Start Class"
          )}
        </ButtonRight>
      </LeftAligned>
    </Card>
  );
}
