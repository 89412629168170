import { useEffect, useState } from "react";
import UserLayout from "../../Layouts/UserLayout/UserLayout";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import StudentOBJ from "../../classes/student.class";
import EmptyData from "../../Components/EmptyData";
import { toast } from "react-toastify";
import {
  ButtonLeft,
  ButtonRight,
  Card,
  LeftAligned,
  CardWrapper,
} from "./Styles";
import { truncateText } from "../../utils/some";
import { getStoredClientUser } from "../../utils/LS";
import Spinner from "../../Components/Spinner";
import { convertMinutesToHoursMinutes } from "../LinkedClass";
import { ShowMore } from "../LinkedClass/Styles";
import Pagination from "../../Components/Pagination";
import userOBJ from "../../classes/user.class";
import SubCard from "./SubCard";
import { PrimaryBtn } from "../../Components/Button";

function Subscription() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [data, setData] = useState([]);
  const [totalPages, setTotalPages] = useState<number>(1);
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);
    StudentOBJ.student_get_all_subscription(
      currentPage,
      getStoredClientUser().userID
    ).then((res: any) => {
      setIsLoading(false);
      setData(res.payload);
    });
  }, []);
  return (
    <UserLayout>
      <CardWrapper>
        <h1>My Subscriptions</h1>
        {data?.length > 0 ? (
          data.map((el: any, i: number) => {
            // subscription card here .....
            return <SubCard data={el} />;
          })
        ) : isLoading ? (
          "Loading..."
        ) : (
          <div className="border border-slate-200 bg-white mt-5 flex justify-center items-center w-full rounded-md py-20">
            {getStoredClientUser().userType == "Tutor" ? (
              <div>
                <p className=" text:xl lg:text-2xl text-gray-500">
                  It appears you've not created a class
                </p>
                <p className="text-center text-sm">
                  Create a class and share with the world.
                </p>
                <div className="mt-10 ">
                  <PrimaryBtn
                    btnType="button"
                    title="Create Class"
                    onBtnClick={() => {
                      navigate("/Class/create-class");
                    }}
                  />
                </div>
              </div>
            ) : (
              <EmptyData data={"Subscription"} />
            )}

            {/* <EmptyData data={"Subscription"} /> */}
          </div>
        )}
        {data?.length > 0 && (
          <Pagination
            totalPages={totalPages}
            currentPage={currentPage}
            callBack={(value: any) => {
              setCurrentPage(value);
              // getStudentRequests(value);
            }}
          />
        )}
      </CardWrapper>
    </UserLayout>
  );
}

Subscription.defaultProps = {
  data: [
    {
      subject: "Physics",
      tutor: "Aluko Opeyemi",
      time_date: "10/09/2022 10:00 am",
      pricing: "NGN100.00/hr",
      duration: "3 hours",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Rutrum ullamcorper lacus tincidunt nibh molestie. Eu placerat lacus,sed arcu elit neque turpis............",
    },
    {
      subject: "Mathematics",
      tutor: "Olabode Eto",
      time_date: "10/09/2022 12:00 am",
      pricing: "NGN100.00/hr",
      duration: "2 hours",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Rutrum ullamcorper lacus tincidunt nibh molestie. Eu placerat lacus,sed arcu elit neque turpis............",
    },
  ],
};
export default Subscription;
