import api from "../API";

class Student {
	// post request
	post_request = async (data: any) => {
		try {
			const response = await api.post(`student/request`, data);
			return response;
		} catch (error) {
			return error;
		}
	};
	get_tutors_applications = async (id: any, page: number) => {
		try {
			const response = await api.get(
				`/student/get-requests/${id}/?page=${page}`
			);
			return response;
		} catch (error) {
			console.log("BE-Error", error);
			return error;
		}
	};
	all_requests = async (page: any, id: string) => {
		try {
			const response = await api.get(`/student/requests/${id}?page=${page}`);
			if (response?.status) return response;
		} catch (error) {
			return error;
		}
	};
	accept_tutor_request = async (data: any) => {
		try {
			const response = await api.post(`/request/?action=accept`, data);
			return response;
		} catch (error) {
			return error;
		}
	};
	getTutorapplication = async ({ name, requestId, type, link }: any) => {
		try {
			const response = type
				? await api.get(`/tutor/profile?link=${link}&type=${type}`)
				: await api.get(`/tutor/profile?name=${name}&requestId=${requestId}`);
			return response;
		} catch (error) {
			return error;
		}
	};
	student_join_class = async (request: any) => {
		try {
			const response = await api.post(`/join-class`, request);
			return response;
		} catch (error) {
			return error;
		}
	};
	student_all_classes = async (
		merithubStudentID: string,
		page: number,
		past?: boolean
	) => {
		try {
			const response = await api.get(
				`/class/${merithubStudentID}?page=${page}&past=${past ? past : false}`
			);
			return response;
		} catch (error) {
			return error;
		}
	};
	student_get_createdClass = async (link: string) => {
		try {
			const response = await api.put(`/get-class`, { link });
			return response;
		} catch (error) {
			return error;
		}
	};
	student_review_tutor = async (data: any) => {
		try {
			const response = await api.post(`/student/review-tutor`, data);
			return response;
		} catch (error) {
			return error;
		}
	};
	student_add_subscription = async (data: any) => {
		try {
			const response = await api.post(`/subscription`, data);
			return response;
		} catch (error) {
			return error;
		}
	};

	student_get_all_subscription = async (page: number, userId: string) => {
		try {
			const response = await api.get(
				`/subscription/?page=${page}&id=${userId}`
			);
			return response;
		} catch (error) {
			return error;
		}
	};

	settle_tutor = async (data: any) => {
		try {
			const response = await api.post(`/settlement`, data);
			return response;
		} catch (error) {
			return error;
		}
	};
}

const StudentOBJ = new Student();
export default StudentOBJ;
