import React, { useState } from "react";

type PropT = {
  label: string;
  placeholder?: string;
  inputType?: any;
  minLength?: any;
  maxLength?: any;
  helper?: string;
  isRequired?: boolean;
  handleChange: Function;
  handleKeyup?: Function;
  setFormErr?: Function;
  value: any;
};
export default function AppInput({
  label,
  placeholder,
  inputType,
  handleChange,
  isRequired,
  minLength,
  maxLength,
  handleKeyup,
  helper,
  setFormErr,
  value,
}: PropT) {
  const [showpassword, setshowpassword] = useState(false);
  const [error, seterror] = useState("");

  return (
    <div className="mb-8">
      <label className="mb-2 block">{label}</label>
      <div
        className={`w-full border border-slate-300 flex items-center rounded-md focus-within:outline-none focus-within:ring-2 focus-with:ring-offset-1 focus-within:ring-primary`}
      >
        <input
          value={value}
          placeholder={placeholder}
          type={showpassword ? "text" : inputType}
          required={isRequired ? true : false}
          minLength={minLength && minLength}
          maxLength={maxLength && maxLength}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            if (inputType === "number") {
              if (event.target.value.length > maxLength) {
                // setFormErr && setFormErr(true);
                return;
              } else {
                handleChange(event.target.value);
                setFormErr && setFormErr(false);
              }
            } else {
              handleChange(event.target.value);
            }
          }}
          onKeyUp={() => {
            handleKeyup && handleKeyup();
            if (minLength && value.length < parseInt(minLength)) {
              seterror(`Entries too short, min: ${minLength}`);
              setFormErr && setFormErr(true);
            } else {
              seterror("");
              setFormErr && setFormErr(false);
            }
          }}
          className="w-full p-[0.84rem] rounded-md outline-none hover:bg-gray-50"
        />
        {inputType === "password" && (
          <div>
            <svg
              width="28"
              height="30"
              viewBox="0 0 30 31"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="cursor-pointer"
              onClick={() => {
                if (value.length) setshowpassword(!showpassword);
              }}
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M26.024 15.5C26.024 15.1489 25.8317 14.9088 25.4472 14.4287C23.7874 12.3562 19.7355 8 15.0003 8C10.2651 8 6.21318 12.3562 4.55338 14.4287C4.16883 14.9088 3.97656 15.1489 3.97656 15.5C3.97656 15.8511 4.16883 16.0912 4.55338 16.5713C6.21318 18.6438 10.2651 23 15.0003 23C19.7355 23 23.7874 18.6438 25.4472 16.5713C25.8317 16.0912 26.024 15.8511 26.024 15.5ZM15.0003 19.25C17.0713 19.25 18.7503 17.5711 18.7503 15.5C18.7503 13.4289 17.0713 11.75 15.0003 11.75C12.9292 11.75 11.2503 13.4289 11.2503 15.5C11.2503 17.5711 12.9292 19.25 15.0003 19.25Z"
                fill="#ccc"
              />
            </svg>
          </div>
        )}
      </div>
      {helper && (
        <p className="text-xs mt-1 mb-8 transition-all ease-in-out duration-200">
          {helper}
        </p>
      )}
      {!helper && error && (
        <p className="text-xs mt-1 mb-2 lg:mb-8 text-red-400 transition-all ease-in-out duration-200">
          {error}
        </p>
      )}
    </div>
  );
}
