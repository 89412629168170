import styled from "styled-components";
import { AppColors } from "../../utils/constants";
import { BreakPoints } from "../../utils/breakpoints";

export const PageLayout = styled.div`
  background: ${AppColors.primary};
  position: relative;
  color: #fff;
  height: 100vh;
  width: 100%;
  // margin: 50px auto;
  overflow-y: scroll;
  /* border: 2px solid ${AppColors.brandRed}; */

  @media (${BreakPoints.xs}) {
    padding: 1rem;
    margin: auto;
  }

  @media (${BreakPoints.large}) {
    padding: 1rem 8rem;
  }
`;

export const PickerCont = styled.div`
  width: 100%;
  height: 44px;
  border: 0.2px solid #84848469;
  border-radius: 6px;
  padding: 10px;
  color: ${AppColors.brandBlack};
  margin-bottom: 0.4rem;
  font-size: 0.8rem;
  display: flex;
  align-items: center;
`;
