import React, { useEffect, useRef, useState } from "react";
import Dropdown from "../../Components/DropDown";
import AppInput from "../../Components/AppInput";
import { subjects } from "../../Shared/ClassData";
import { PrimaryBtn } from "../../Components/Button";
import axios from "axios";

export default function SubjectDetails({
	setcurrentStep,
	setstepData,
	stepData,
}: any) {
	const [subject, setsubject] = useState(stepData[0] ?? "");
	const [level, setlevel] = useState(stepData[1] ?? "100Level");
	const [description, setdescription] = useState(stepData[2] ?? "");
	const [selectedImage, setSelectedImage] = useState<File | null>(null);

	const [matchingSubjects, setMatchingSubjects] = useState<string[]>([]);
	const fileInputRef: any = useRef(null);

	useEffect(() => {
		const delaySearch = setTimeout(() => {
			const filteredSubjects = subjects.filter((sub) =>
				sub.toLowerCase().includes(subject.toLowerCase())
			);
			setMatchingSubjects(filteredSubjects);
		}, 300); // Debounce time in milliseconds

		return () => clearTimeout(delaySearch);
	}, [subject]);

	const handleThumbanil = async (e: any) => {
		const file = e.target.files[0];

		const formData = new FormData();
		formData.append("file", file);
		formData.append("upload_preset", "rreoys4n");
		if (file) {
			// Set the selected image in the state
			// setSelectedImage(file);

			// Optionally, you can display a preview of the selected image
			const reader = new FileReader();
			reader.onload = (event: any) => {
				// Use the "event.target.result" as the image source for preview
				const previewSrc = event.target.result;
				// Display the preview using an <img> element or any other way you prefer
				// For example, you can render it inside a <div>
				const previewContainer = document.getElementById("image-preview");
				if (previewContainer) {
					previewContainer.innerHTML = `<img src="${previewSrc}" class="w-full h-full object-contain" alt="Thumbnail Preview" />  <button type="button" class="absolute border border-gray-300 inset-0 m-auto w-50 h-50 text-white px-4 py-2 ">
          Change
        </button>`;
				}
			};

			reader.readAsDataURL(file);
			await axios
				.post(
					"https://api.cloudinary.com/v1_1/classhour/image/upload",
					formData
				)
				.then((res: any) => {
					setSelectedImage(res.data["secure_url"]);
				});
		}
	};
	const handleSubmit = (e: React.FormEvent) => {
		e.preventDefault();
		setstepData([subject, level, description, selectedImage]);
		setcurrentStep(2);
	};
	return (
		<div className=" py-2 text-gray-600">
			<div className="border-l-4 border-orange-500 pl-2">
				<h2 className="text-2xl"> Subject Details</h2>
				<p className="font-light">Tell us about the subject you wish to take</p>
			</div>
			<div className="mt-20 p-2 m-auto lg:w-6/12 pb-20">
				<form onSubmit={handleSubmit}>
					<AppInput
						minLength={5}
						value={subject}
						label="Subject"
						handleChange={(value: any) => setsubject(value)}
					/>
					{subject.length > 4 && matchingSubjects.length > 0 && (
						<ul className="flex gap-2 mb-2 w-full flex-wrap">
							{matchingSubjects.map((subject, index) => (
								<li
									className="rounded-full font-bold p-2"
									key={index}
									onClick={() => setsubject(matchingSubjects[index])}
								>
									{subject}
								</li>
							))}
						</ul>
					)}
					<Dropdown
						label="Level"
						className="w-full"
						placeholder="Select Level"
						options={[
							{ value: "Jss1", label: "Jss1" },
							{ value: "Jss2", label: "Jss2" },
							{ value: "Jss3", label: "Jss3" },
							{ value: "SS1", label: "SS1" },
							{ value: "SS2", label: "SS2" },
							{ value: "SS3", label: "SS3" },
							{ value: "100Level", label: "100Level" },
							{ value: "200Level", label: "200Level" },
							{ value: "300Level", label: "300Level" },
							{ value: "400Level", label: "400Level" },
							{ value: "500Level", label: "500Level" },
							{ value: "Beginner", label: "Beginner" },
							{ value: "Intermediate", label: "Intermediate" },
							{ value: "Advanced", label: "Advanced" },
						]}
						onSelect={(value: any) => setlevel(value)}
					/>
					<div className="flex flex-col mt-8 mb-12">
						<label className="mb-2">Description</label>
						<textarea
							minLength={20}
							required
							value={description}
							className="border border-gray-300 h-40 p-2 outline-none"
							placeholder="Tell your subscriber more about this course"
							onChange={(e: any) => setdescription(e.target.value)}
						></textarea>
					</div>
					<div className="flex flex-col mt-8 mb-12">
						<label className="mb-2">Thumbnail(optional)</label>
						<input
							type="file"
							accept="image/*"
							ref={fileInputRef}
							onChange={handleThumbanil}
							style={{ display: "none" }}
						/>
						{!selectedImage && (
							<button
								type="button"
								onClick={() => fileInputRef && fileInputRef?.current?.click()}
								className="border border-gray-300 h-40 p-2 outline-none"
							>
								Upload thumbnail
							</button>
						)}
						{selectedImage && (
							<div
								id="image-preview"
								className="relative w-100 border bg-gray-200 border-gray-300 cursor-pointer p-2 outline-none"
								onClick={() => fileInputRef && fileInputRef?.current?.click()}
							></div>
						)}
					</div>
					<PrimaryBtn
						isDisabled={subject && level && description ? false : true}
						title="Proceed"
						btnType="submit"
						onBtnClick={() => ""}
					/>
				</form>
			</div>
		</div>
	);
}
