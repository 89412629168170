import React from "react";
import styled from "styled-components";
import { AppColors } from "../../utils/constants";
import Spinner from "../Spinner";

const PrimaryButton = styled.button`
  background: ${AppColors.brandRed};
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  height: 50px;
  border: none;
  border-radius: 9px;
  text-transform: uppercase;
  cursor: pointer;
  font-weight: 600;
  width: 100%;
`;

const OutlinedButton = styled.button`
  /* border: 1px solid ${AppColors.brandRed}; */
  border: 1px solid red;
  color: ${AppColors.brandRed};
  padding: 1rem 1.4rem;
  border-radius: 0.4rem;
  text-transform: uppercase;
  cursor: pointer;
  background: #fff;
  font-weight: 600;
`;

type btnPropT = {
  title: string;
  btnType: "button" | "submit" | "reset" | undefined;
  onBtnClick: Function | null;
  isLoading?: boolean;
  isDisabled?: boolean;
};

export function PrimaryBtn({
  title,
  btnType,
  onBtnClick,
  isLoading,
  isDisabled,
  ...rest
}: btnPropT) {
  return (
    <PrimaryButton
      disabled={isDisabled}
      type={btnType}
      onClick={(e) => onBtnClick && onBtnClick(e)}
    >
      {isLoading ? (
        <Spinner isLoading={isLoading} />
      ) : (
        <div className="text-sm lg:text-base cursor-pointer">{title}</div>
      )}
    </PrimaryButton>
  );
}

export function OutlinedBtn({ title, onBtnClick }: any) {
  return <OutlinedButton onClick={() => onBtnClick()}>{title}</OutlinedButton>;
}
