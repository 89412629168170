import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import UserLayout from "../../Layouts/UserLayout/UserLayout";
import { useNavigate, useParams } from "react-router-dom";
import StudentOBJ from "../../classes/student.class";
import {
  ButtonLeft,
  ButtonRight,
  Card,
  LeftAligned,
  CardWrapper,
  ShowMore,
} from "./Styles";
import { truncateText } from "../../utils/some";
import { getStoredClientUser } from "../../utils/LS";
import Spinner from "../../Components/Spinner";
import { toast } from "react-toastify";
import MetaDecorator from "../../utils/metadecorator";

export function convertMinutesToHoursMinutes(minutes: number) {
  var hours = Math.floor(minutes / 60);
  var remainingMinutes = minutes % 60;

  if (hours > 0) {
    if (remainingMinutes === 0) {
      return hours + " hour(s)";
    } else {
      return hours + " hr(s) and " + remainingMinutes + " min(s)";
    }
  } else {
    return remainingMinutes + " min(s)";
  }
}

function LinkedClass(props: any) {
  const { id, classId } = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [showMOre, setShowMOre] = useState(false);
  const [classDetail, setClassDetail] = useState<any>();
  const { userID } = getStoredClientUser();
  const navigate = useNavigate();
  const [data, setdata] = useState([]);
  const [subscribers, setsubscribers] = useState<any[]>([]);

  const getClassDetails = async () => {
    const url = new URL(window.location.href);
    const link = url.pathname.substr(1);
    const response: any = await StudentOBJ.student_get_createdClass(link);
    setClassDetail(response.response);
    setsubscribers(response.response.subscribers);
  };

  const handleSubscribe = async () => {
    setIsLoading(true);
    if (getStoredClientUser().userID === undefined) {
      toast.info("To continue, you need to log in or create an account.");
      setTimeout(() => {
        navigate("/");
      }, 4000);
    } else {
      const response: any = await StudentOBJ.student_add_subscription({
        ...classDetail,
        userId: getStoredClientUser().userID,
      });
      if (response.status) {
        toast.success(response.message);
        navigate("/subscription");
      } else {
        toast.error(response.message);
        setIsLoading(false);
      }
    }
  };

  const getTutorProfile = async (data: any) => {
    navigate("/tutor-request", { state: data });
  };

  useEffect(() => {
    getClassDetails();
  }, []);

  return (
    <>
      <MetaDecorator
          description={classDetail?.description}
          imageUrl={
            classDetail?.thumbnail
          }
          imageAlt="..."
          title={classDetail?.subject}
        />
    <UserLayout>
      {classDetail && (
        <CardWrapper>

          <Card>
            <div className="flexy flex-col md:flex-row">
              {classDetail?.thumbnail && (
                <div className="img_container">
                  <img
                    src={classDetail?.thumbnail}
                    className="w-full h-full object-contain"
                    alt="Thumbnail Preview"
                    style={{ borderRadius: 20 }}
                  />
                </div>)
              }

              <div>
                <div className="flex">
                  <p>Subject:</p>
                  <p>
                    <b>{classDetail?.subject}</b>
                  </p>
                </div>
                <p>
                  {!showMOre && classDetail?.description?.length > 130
                    ? truncateText(classDetail?.description, 130)
                    : classDetail?.description}
                </p>
                {classDetail?.description?.length > 130 && (
                  <ShowMore onClick={() => setShowMOre(!showMOre)}>
                    {showMOre ? "Show less" : "Show More"}
                  </ShowMore>
                )}

                <div className="flex">
                  <p>Tutor: </p>
                  <p>
                    <b>{classDetail?.tutorID?.fullname}</b>
                  </p>
                </div>
                <div className="flex">
                  <p>Time and Date:</p>
                  <p>
                    <b> {new Date(classDetail?.startTime).toLocaleString()}</b>
                  </p>
                </div>
                <div className="flex">
                  <p>Pricing:</p>
                  <p>
                    <b>
                      {classDetail?.amount
                        ? "NGN" + classDetail?.amount.toFixed(2) + "/hr"
                        : "-----"}
                    </b>
                  </p>
                </div>
                <div className="flex">
                  <p>Duration:</p>
                  <p>
                    <b>{convertMinutesToHoursMinutes(classDetail?.duration)}</b>
                  </p>
                </div>
                <LeftAligned>
                  <ButtonLeft
                    onClick={() =>
                      getTutorProfile({
                        link: classDetail.link,
                        type: "subscribeClass",
                      })
                    }
                  >
                    View Profile
                  </ButtonLeft>
                  <ButtonRight
                    disabled={
                      (getStoredClientUser().userID &&
                        getStoredClientUser().userID ===
                          subscribers.includes(getStoredClientUser().userID)) ||
                      isLoading
                    }
                    onClick={handleSubscribe}
                  >
                    {getStoredClientUser().userID &&
                    getStoredClientUser().userID ===
                      subscribers.includes(getStoredClientUser().userID) ? (
                      "Subscribed"
                    ) : isLoading ? (
                      <Spinner isLoading={isLoading} />
                    ) : (
                      "Subscribe"
                    )}
                  </ButtonRight>
                </LeftAligned>
              </div>
            </div>
          </Card>
        </CardWrapper>
      )}
    </UserLayout>
    </>
  );
}

LinkedClass.defaultProps = {
  subject: "Physics",
  tutor: " Aluko Folajimi",
  time_date: "10/09/2022 10:00 am",
  pricing: "NGN100.00/hr",
  duration: "3 hours",
  description:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Rutrum ullamcorper lacus tincidunt nibh molestie. Eu placerat lacus, sed arcu elit neque turpis............",
};

export default LinkedClass;
