import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PageNav from "../../Layouts/UserLayout/PageNav";
import UserLayout from "../../Layouts/UserLayout/UserLayout";
import RequestCard from "../../Components/RequestCard";
import Pagination from "../../Components/Pagination";
import EmptyState from "../../Components/EmptyData";
import { PageLayout } from "./Styles";
import { getStoredClientUser } from "../../utils/LS";
import userOBJ from "../../classes/user.class";
import StudentOBJ from "../../classes/student.class";
import RequestCardPage from "../../Components/RequestCardPage";

export default function Requestpage() {
  const [requestData, setRequestData] = useState<[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, settotalPages] = useState<number>(0);
  const navigate = useNavigate();
  const { id: id } = useParams<{
    id: string;
  }>();

  const { userType, userID, merithubUserID } = getStoredClientUser();

  const getStudentRequests = async (page: number) => {
    const response: any = await StudentOBJ.all_requests(
      currentPage,
      merithubUserID
    );
    if (response?.status) {
      setRequestData(response?.payload);
      settotalPages(response?.totalPages);
    }
  };

  const getRequest = async () => {
    const response: any = await userOBJ.single_requests(id);
    if (response?.status) {
      //   setRequestData(response?.payload);
      console.log("payload", response?.payload);
      const dataArr = response?.payload?.filter((obj: any) => obj?._id === id);
      setRequestData(dataArr);
      settotalPages(response?.totalPages);
      console.log("response timeline", response);
    }
  };

  useEffect(() => {
    getRequest();
    // console.log("user=====>", getStoredClientUser());
  }, [currentPage]);

  return (
    <UserLayout>
      {/* <PageNav isActive={true} title="Requests" /> */}

      <div className="mt-32">
        <PageLayout>
          {requestData?.length ? (
            requestData?.map((obj, index) => {
              return (
                <React.Fragment key={index}>
                  <RequestCardPage data={obj} />
                </React.Fragment>
              );
            })
          ) : (
            <EmptyState data="Request(s)" />
          )}
          {!requestData && <h3>Loading...</h3>}
        </PageLayout>
      </div>
    </UserLayout>
  );
}
