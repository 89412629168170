import React, { useEffect, useState } from "react";
import {
	Background,
	InviteCard,
	Wrapper,
	Container,
	ButtonContainer,
} from "./styles";
import { CardButton } from "../RequestCardPage/Styles";
import asterisks from "../../Assets/icons/accept-invite-pop-up-union.png";
import dismiss from "../../Assets/icons/dismiss.png";
import moment from "moment";
import { useNavigate, Link } from "react-router-dom";
import { getStoredClientUser } from "../../utils/LS";
import studentRequest from "../../classes/student.class";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import {
	Grid,
	Checkbox,
	Typography,
	useMediaQuery,
	useTheme,
	FormControlLabel,
	Radio,
} from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";

import Rating from "@mui/material/Rating";
import { toast } from "react-toastify";
import { PrimaryBtn } from "../Button";

const Transition = React.forwardRef(function Transition(
	props: TransitionProps & {
		children: React.ReactElement<any, any>;
	},
	ref: React.Ref<unknown>
) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default function Modal({ data }: any) {
	const { userType, userID } = getStoredClientUser();

	const [isLoading, setisLoading] = useState(false);
	const navigate = useNavigate();
	const theme = useTheme();
	const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));
	const [selectedOption, setSelectedOption] = useState(0);
	const [ratingValue, setRatingValue] = useState(0);
	const [radioValue, setRadioValue] = useState("");
	const [textareaValue, setTextareaValue] = useState("");

	const [open, setOpen] = useState(true);

	const handleClose = () => {
		setOpen(false);
	};

	const handleSelection = (val: any) => {
		if (selectedOption === val) {
			setSelectedOption(0);
		} else {
			setSelectedOption(val);
		}
	};

	useEffect(() => {
		if (data === null) {
			toast.error("You are not authorized to access this page.");
			navigate("/"); // Redirect to home page or any other page
		}
	}, [navigate]);

	const handleRadioChange = (event: any) => {
		setRadioValue(event.target.value);
	};

	const handleTextareaChange = (event: any) => {
		setTextareaValue(event.target.value);
	};
	// console.log(getStoredClientUser());
	const handleSubmit = async () => {
		setisLoading(true);

		let payload = {
			action: selectedOption == 1 ? "pay" : "dont_pay",
			studentID: getStoredClientUser().userID,
			studentName: getStoredClientUser().fullname,
			classId: data?.classId,
			classInfo: data,
			feedback: textareaValue,
			rating: ratingValue,
			ratingText: radioValue,
		};
		console.log(payload);
		const response: any = await studentRequest.settle_tutor(payload);
		if (response) {
			if (response?.status) {
				toast.success(response?.message);
				setisLoading(false);
				navigate("/class");
			} else {
				toast.error(response?.message);
				setisLoading(false);
			}
		} else {
			toast.error(response?.message);
			setisLoading(false);
		}
	};

	return (
		<div className="s-modal" style={{ width: 600 }}>
			<Dialog
				open={open}
				TransitionComponent={Transition}
				keepMounted
				sx={{
					minWidth: isLargeScreen ? 700 : "90vw",
				}}
				aria-describedby="alert-dialog-slide-description"
			>
				<img
					src={dismiss}
					width={20}
					onClick={handleClose}
					style={{
						position: "absolute",
						right: 10,
						top: 10,
						cursor: "pointer",
					}}
				/>
				<DialogTitle
					style={{
						fontWeight: "bold",
						fontSize: "27px",
						textAlign: "center",
						marginBottom: "1rem",
					}}
				>
					{"Settlement"}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-slide-description">
						<Grid container alignItems="center" justifyContent="center">
							<Grid
								item
								onClick={() =>
									data?.userType === "Tutor" ? null : handleSelection(1)
								}
							>
								<Checkbox
									checked={selectedOption == 1}
									color="error"
									style={{ borderColor: "#000" }}
									disabled={data?.userType === "Tutor" ?? false}
								/>
							</Grid>
							<Grid item>
								<Typography variant="body1">
									I have attended the class
								</Typography>
							</Grid>
						</Grid>

						<Grid container alignItems="center" justifyContent="center">
							<Grid item onClick={() => handleSelection(2)}>
								<Checkbox
									checked={selectedOption == 2}
									color="error"
									style={{ borderColor: "#000" }}
								/>
							</Grid>
							<Grid item>
								<Typography variant="body1">
									I didn't attended the class
								</Typography>
							</Grid>
						</Grid>

						{selectedOption === 1 && (
							<>
								<Grid container alignItems="center" justifyContent="center">
									<div style={{ textAlign: "center" }}>
										<Typography
											variant="h2"
											style={{
												fontWeight: "bold",
												fontSize: "20px",
												margin: "10px 0",
											}}
										>
											Add Feedback
										</Typography>
										<Rating
											name="customized-empty"
											value={ratingValue}
											color="error"
											onChange={(event, newValue) => {
												if (newValue !== null) {
													setRatingValue(newValue);
												}
											}}
										/>
									</div>
								</Grid>
								<Grid container alignItems="center" justifyContent="center">
									<Grid>
										<FormControlLabel
											control={
												<Radio
													checked={radioValue === "Satisfactory"}
													onChange={handleRadioChange}
													value="Satisfactory"
													color="error"
												/>
											}
											label="Satisfactory"
										/>
									</Grid>
									<Grid>
										<FormControlLabel
											control={
												<Radio
													checked={radioValue === "Unsatisfactory"}
													onChange={handleRadioChange}
													value="Unsatisfactory"
													color="error"
												/>
											}
											label="Unsatisfactory"
										/>
									</Grid>
								</Grid>
								<Grid container alignItems="center" justifyContent="center">
									<textarea
										value={textareaValue}
										onChange={handleTextareaChange}
										placeholder="Enter your feedback here"
										style={{
											width: "90%",
											height: "100px",
											margin: "10px auto",
											padding: "10px",
											border: "1px solid #000",
										}}
									/>
								</Grid>
							</>
						)}

						{selectedOption == 2 && (
							<>
								<Grid container alignItems="center" justifyContent="center">
									<div style={{ textAlign: "center" }}>
										<Typography
											variant="h2"
											style={{
												fontWeight: "bold",
												fontSize: "20px",
												margin: "10px 0",
											}}
										>
											Raise Dispute
										</Typography>
									</div>
								</Grid>
								<Grid container alignItems="center" justifyContent="center">
									<Grid>
										<FormControlLabel
											control={
												<Radio
													checked={radioValue === "poor internet"}
													onChange={handleRadioChange}
													value="poor internet"
													color="error"
												/>
											}
											label="Poor Internet"
										/>
									</Grid>
									<Grid>
										<FormControlLabel
											control={
												<Radio
													checked={radioValue === "poor audio"}
													onChange={handleRadioChange}
													value="poor audio"
													color="error"
												/>
											}
											label="Poor Audio"
										/>
									</Grid>
								</Grid>
								<Grid container alignItems="center" justifyContent="center">
									<textarea
										value={textareaValue}
										onChange={handleTextareaChange}
										placeholder="Enter your dispute reason here"
										style={{
											width: "90%",
											height: "100px",
											margin: "10px auto",
											padding: "10px",
											border: "1px solid #000",
										}}
									/>
								</Grid>
							</>
						)}
						<div style={{ maxWidth: 280, margin: "auto" }}>
							<PrimaryBtn
								isLoading={isLoading}
								title="Submit"
								btnType="submit"
								onBtnClick={() => handleSubmit()}
								isDisabled={isLoading || selectedOption === 0}
							/>
						</div>
					</DialogContentText>
				</DialogContent>
			</Dialog>
		</div>
	);
}
