import styled from "styled-components";

export const Container = styled.div`
	width: calc(100% - 60px);
	background: #fff;
	height: calc(100% - 120px);
	margin: 0 auto;
	position: absolute;
	padding: 20px;
	top: 90px;
	left: 32px;
	overflow: hidden;
	border-radius: 10px;

	@media (max-width: 600px) {
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		margin: 0;
		padding: 0;
		border-radius: 0;
	}
`;
export const Wrapper = styled.div<{ isMobile?: boolean }>`
	display: ${({ isMobile }) => (!isMobile ? "flex" : "block")};
	height: 100%;
`;
export const Flex = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	// position: absolute;
`;

export const FirstSection = styled.div`
	padding: 0 22px 8px 8px;
	border-right: 1px solid #dbdbdb;
	height: 100%;
	overflow-y: scroll;
	scrollbar-width: none;
	transition: 0.3s ease all;
	flex: 0.2;

	@media (max-width: 600px) {
		padding-top: 90px;
	}
`;

export const SecondSection = styled.div`
	padding-left: 8px;
	padding-bottom: 16px;
	height: 100%;
	overflow: hidden;
	flex: 0.8;
	@media (max-width: 600px) {
		padding-top: 90px;
	}
`;
export const ChatContainer = styled.div`
	display: flex;
	// justify-content: space-between;
	width: 100%;
	gap: 5px;
	position: relative;
	cursor: pointer;
	margin-top: 10px;
	border-bottom: 1px solid #dbdbdb;
	padding-bottom: 16px;
	transition: 0.3s ease all;
	&:hover {
		background: rgb(219 219 219 / 21%);
		transform: translateY(-3px);
	}
	.header {
		display: flex;
		width: 100%;
	}
	section {
		flex-direction: column;
		min-width: 300px;
		.text {
			margin-top: 10px;
			font-size: 14px;
			color: #808080;
		}
		@media (max-width: 600px) {
			min-width: 81%;
		}
	}
	.header {
		align-items: center;
		justify-content: space-between;
		width: 100%;

		h1 {
			color: #333333;
			font-size: 16px;
			font-weight: 600;
		}
		span {
			p {
				font-size: 12px;
				color: #808080;
			}
		}
	}
	img {
		width: 40px;
		height: 40px;
		border-radius: 50%;
		// margin-right: 10px;
		background: #dbdbdb;
		flex: none;
	}
	.indicator {
		position: absolute;
		top: 25px;
		left: 33px;
		width: 8px;
		height: 8px;
		border-radius: 50%;
		z-index: 1;
	}
`;

export const MessageContainer = styled.div`
	// padding-left: 8px;
	// padding-bottom: 16px;
	// height: 100%;
	// overflow-y: auto;
	// flex: 0.8;
`;

export const MessageHeader = styled.div`
	display: flex;
	gap: 8px;
	align-items: center;
	margin-top: 10px;
	border-bottom: 1px solid #dbdbdb;
	padding-bottom: 16px;

	img {
		width: 40px;
		height: 40px;
		border-radius: 50%;
		margin-right: 10px;
		background: #dbdbdb;
		flex: none;
	}
	h1 {
		color: #333333;
		font-size: 16px;
		font-weight: 600;
	}
`;

export const MessageBody = styled.div`
	position: relative;
	height: 100%;
	// background: red;
	form {
		position: absolute;
		bottom: 0px;
		left: 20px;
		width: 100%;
		z-index: 1000;
		@media (max-width: 768px) {
			left:0;
			}
		svg {
			position: absolute;    
			cursor : pointer;
            bottom: 13px;
            right: 76px;
			@media (max-width: 600px) {
		right:13px
	}
}
		}
		input {
			width: 96%;
			margin: auto !important;
			padding: 19px 12px;
			border: 1px solid #dbdbdb;
			outline: none;
			border-radius: 50px;
			@media (max-width: 768px) {
				width:100%
			}
		}
		.content{
			height 100%;
			overflow-y: scroll;
			// padding-bottom: 120px;
	overflow-y: scroll;
	scrollbar-width: none;
	transition: 0.3s ease all;
	p {
				font-size: 12px;
				color: #808080;
			}
			img {
		width: 40px;
		height: 40px;
		border-radius: 50%;
		margin-right: 10px;
		background: #dbdbdb;
				margin-top:12px;
		flex: none;
	}
			div{
				max-width:500px;
			border-radius: 20px 0 20px 0;
				min-height:50px;
				margin-top:12px;
				padding:12px;
				@media (max-width: 768px) {
				padding:12px 5px;
			}
			}
		}
		.self{
			margin-left:auto;
			background: rgb(227 219 219 / 29%);
			margin-right:20px;

		}
		.other{
			margin-right:auto;	
color: #fff;
			background: linear-gradient(90deg, rgb(22, 27, 69) 0%, rgb(37, 34, 89) 37%, rgb(57, 49, 106) 68%, rgb(73, 54, 125) 100%);
		}
	}
`;
