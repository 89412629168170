import React, { useEffect } from "react";
import { getStoredClientUser } from "../../utils/LS";
import userOBJ from "../../classes/user.class";
import {
  Card,
  CardContainer,
  ContentContainer,
  PageLayout,
  Taskbar,
} from "./Styles";
import TimelineIcon from "../../Assets/icons/TimelineIcom.svg";
import NewBadgeIcon from "../../Assets/icons/newBadge.svg";
import ProfileSettings from "../../Assets/icons/ProfileSettings.svg";
import RequestIcon from "../../Assets/icons/RequestIcon.svg";
import WalletIcon from "../../Assets/icons/WalletIcon.svg";
import Subscriptions from "../../Assets/icons/Subscription.svg";
import StackIcon from "../../Assets/icons/stack.svg";

import { Logout } from "../../utils/some";
import DashboardHeader from "../../Layouts/DBHeader";
import { Link, useNavigate } from "react-router-dom";

const modules = [
  { id: 1, name: "Timeline", icon: TimelineIcon, path: "/timeline" },
  { id: 2, name: "Profile Settings", icon: ProfileSettings, path: "/settings" },
  { id: 3, name: "Post Request", icon: RequestIcon, path: "/post-request" },
  { id: 4, name: "Wallet", icon: WalletIcon, path: "/wallet" },
  {
    id: 5,
    name: "My Subscriptions",
    icon: Subscriptions,
    path: "/subscription",
  },
];

const tutorModules = [
  { id: 1, name: "Timeline", icon: TimelineIcon, path: "/timeline" },
  { id: 2, name: "Profile Settings", icon: ProfileSettings, path: "/settings" },
  { id: 3, name: "My Applications", icon: RequestIcon, path: "/submissions" },
  { id: 4, name: "Wallet", icon: WalletIcon, path: "/wallet" },
  // { id: 5, name: "My Students", icon: ProfileSettings, path: "" },
  {
    id: 6,
    name: "My Subscriptions",
    icon: Subscriptions,
    path: "/subscription",
  },
  // { id: 6, name: "Class", icon: StackIcon, path: "/Class" },
];

export default function Dashboard() {
  const navigate = useNavigate();
  const goto = (path: string) => {
    navigate(path);
  };

  const studentCardList = modules.map((obj: any, index: number) => (
    <Card key={index} onClick={() => goto(obj.path)}>
      <img src={obj.icon} alt="" />
      <span>{obj.name}</span>
    </Card>
  ));

  const tutorCardList = tutorModules.map((obj: any, index: number) => (
    <Card key={index} onClick={() => goto(obj.path)}>
      <img src={obj.icon} alt="" />
      <span>{obj.name}</span>
    </Card>
  ));

  useEffect(() => {
    // console.log("data", getStoredClientUser());
  }, []);

  return (
    <div className="lg:px-20 bg-[#161B45] h-screen">
      {/* <PageLayout> */}

      <DashboardHeader pagename="Dashboard" />
      {getStoredClientUser().userType == "Tutor" ? (
        <div className="flex justify-end px-2 lg:px-0 mt-5 items-center sticky top-16 mb-5">
          <div className="relative">
            <img
              src={NewBadgeIcon}
              alt="New Badge"
              className="w-10 absolute -top-2 -left-6"
            />
            <div
              className="text-sm  bg-orange-500 rounded-lg text-center px-4 cursor-pointer h-10 flex items-center text-white"
              onClick={() => navigate("/Class/create-class")}
            >
              Create Class
            </div>
          </div>
        </div>
      ) : (
        <div className="flex justify-end mt-16">
          {/* <div className="mt-4 mb-8 font-bold p-2 bg-orange-500 rounded-lg w-4/12 lg:w-1/12 text-center">
            Dashboard
          </div> */}
        </div>
      )}

      {/* <ContentContainer> */}
      <div className="bg-white min-h-60 py-10 rounded-md">
        <CardContainer>
          {getStoredClientUser().userType == "Tutor"
            ? tutorCardList
            : studentCardList}
        </CardContainer>
      </div>
      {/* </ContentContainer> */}
      {/* {  getStoredClientUser().userType == "Tutor" && <Taskbar>
          
          </Taskbar>} */}
      {/* </PageLayout> */}
    </div>
  );
}
