export const ClassData = [
	{
		date: "18/02/2022",
		status: "OPEN",
		desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Rutrum ullamcorper lacus tincidunt nibh molestie. Eu placerat lacus, sed arcu elit neque turpis",
		subject: "physics",
		language: "english",
		tutor: "John Doe",
	},
	{
		date: "18/02/2022",
		status: "OPEN",
		desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Rutrum ullamcorper lacus tincidunt nibh molestie. Eu placerat lacus, sed arcu elit neque turpis",
		subject: "physics",
		language: "english",
		tutor: "John Doe",
	},
	{
		date: "18/02/2022",
		status: "OPEN",
		desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Rutrum ullamcorper lacus tincidunt nibh molestie. Eu placerat lacus, sed arcu elit neque turpis",
		subject: "physics",
		language: "english",
		tutor: "John Doe",
	},
];

export const subjects: string[] = [
	"Mathematics",
	"Physics",
	"Chemistry",
	"Biology",
	"Use of English",
	"MIcrobiology",
	"Biochemistry",
	"Agriculture",
	"Computer Science",
	"English Language",
	"Economics",
	"Electrical Electronics",
	"Engineering",
	"Sociology",
	"Accounting",
	"Nursing",
	"Physiology",
	"Business Admin",
	"Human Anatomy",
	"Information System",
	"Cyber Security",
];
