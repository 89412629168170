export const ActivityData = [
  {
    email: "lawblaze",
    paymentType: "completed class",
    amount: "20",
    dateAndTime: "10/10/10 10:24",
    status: "Failed",
  },

  {
    email: "lawblaze",
    paymentType: "completed class",
    amount: "20",
    dateAndTime: "10/10/10 10:24",
    status: "Failed",
  },
  {
    email: "lawblaze",
    paymentType: "completed class",
    amount: "20",
    dateAndTime: "10/10/10 10:24",
    status: "Pending",
  },
  {
    email: "lawblaze",
    paymentType: "completed class",
    amount: "20",
    dateAndTime: "10/10/10 10:24",
    status: "Sucess",
  },
  {
    email: "lawblaze",
    paymentType: "completed class",
    amount: "20",
    dateAndTime: "10/10/10 10:24",
    status: "Sucess",
  },
  {
    email: "lawblaze",
    paymentType: "completed class",
    amount: "20",
    dateAndTime: "10/10/10 10:24",
    status: "Sucess",
  },
];
